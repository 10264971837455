import React, { useRef, useEffect, useState } from 'react'
import Chart from 'chart.js'
import { get, reduce, has } from 'lodash'
import classes from './BuProjects.module.scss'

Chart.defaults.global.defaultFontFamily = 'Titillium Web'

const getCompletionDataSetArray = (data) => ([
    (get(data, 'completed_0_25_percent') || 0),
    (get(data, 'completed_26_50_percent') || 0),
    (get(data, 'completed_51_75_percent') || 0),
    (get(data, 'completed_76_100_percent') || 0),
])
const xLabels = ['0-25', '26-50', '51-75', '76-100']

const BuProjectsRetailPlanningGraphCount = ({graphData}) => {
    const chartContainer = useRef('barGraph')
    const [chartInstance, setChartInstance] = useState(null)
    const [estdCompletionFilters, setEstdCompletionFilters] = useState({
        'O_to_3': true,
        '3_to_6': true,
        'gt_6': true,
        'delayed': true,
    })

    const toggleFilter = (event) => {
        const filterFor = event.name
        if (filterFor === 'all') {
            setEstdCompletionFilters(prev => {
                const newAllValue = !(prev['O_to_3'] && prev['3_to_6'] && prev['gt_6'] && prev['delayed'])

                return {
                    'O_to_3': newAllValue,
                    '3_to_6': newAllValue,
                    'gt_6': newAllValue,
                    'delayed': newAllValue,
                }
            })
        } else if (has(estdCompletionFilters, filterFor)) {
            setEstdCompletionFilters(prev => ({
                ...prev,
                [filterFor]: !prev[filterFor]
            }))
        }
    }

    const getChartConfig = () => {
        const ongoingGraphdata = graphData.ongoing || {}
        let completed_0to25 = graphData.completed_0_25_percent
        let completed_26to50 = graphData.completed_26_50_percent
        let completed_51to75 = graphData.completed_51_75_percent
        let completed_76to100 = graphData.completed_76_100_percent
let percentageData = []
if(graphData.completed_0_25_percent){
    percentageData[0] = graphData.completed_0_25_percent
}
if(graphData.completed_26_50_percent){
    percentageData[1] = graphData.completed_26_50_percent
}
if(graphData.completed_51_75_percent){
    percentageData[2] = graphData.completed_51_75_percent
}
if(graphData.completed_76_100_percent){
    percentageData[3] = graphData.completed_76_100_percent
}
        if (estdCompletionFilters['O_to_3']) {
            completed_0to25 = getCompletionDataSetArray(ongoingGraphdata[0])
        }

        if (estdCompletionFilters['3_to_6']) {
            completed_26to50 = getCompletionDataSetArray(ongoingGraphdata[1])
        }

        if (estdCompletionFilters['gt_6']) {
            completed_51to75 = getCompletionDataSetArray(ongoingGraphdata[2])
        }

        if (estdCompletionFilters['delayed']) {
            completed_76to100 = getCompletionDataSetArray(ongoingGraphdata[3])
        }
        
        const totals = reduce([completed_0to25, completed_26to50, completed_51to75, completed_76to100], (acc, eachDataSet) => {
            return [
                acc[0] + eachDataSet[0],
                acc[1] + eachDataSet[1],
                acc[2] + eachDataSet[2],
                acc[3] + eachDataSet[3]
            ]
        }, Array(4).fill(0))

        const ctx = chartContainer.current.getContext('2d')
        let gradientForO_to_3 = ctx.createLinearGradient(0, 0, 0, 500)
        gradientForO_to_3.addColorStop(1, '#00a8ec')//dark blue
        gradientForO_to_3.addColorStop(0, '#00e0bc')

        let gradientFor3_to_6 = ctx.createLinearGradient(0, 0, 0, 500)
        gradientFor3_to_6.addColorStop(0, '#ffbf45')//green
        gradientFor3_to_6.addColorStop(1, '#ff8309')

        let gradientForGt_6 = ctx.createLinearGradient(0, 0, 0, 500)
        gradientForGt_6.addColorStop(0, '#ff7bda')//purple
        gradientForGt_6.addColorStop(1, '#e640b7')

        let gradientForDelayed = ctx.createLinearGradient(0, 0, 0, 500)
        gradientForDelayed.addColorStop(0, '#ff588c')//pink
        gradientForDelayed.addColorStop(1, '#fac159')

        let gradientForProjectCost = ctx.createLinearGradient(0, 0, 0, 500)
        gradientForProjectCost.addColorStop(1, '#1f47ac')
        gradientForProjectCost.addColorStop(0, '#0897c9')

        let gradientForTotalExpenses = ctx.createLinearGradient(0, 0, 0, 500)
        gradientForTotalExpenses.addColorStop(0, '#fa9b0c')//green
        gradientForTotalExpenses.addColorStop(1, '#f7cb19')

        let gradientForCompletion= ctx.createLinearGradient(0, 0, 0, 500)
        gradientForCompletion.addColorStop(0, '#b5b09a')//purple
        gradientForCompletion.addColorStop(1, '#e3ddc5')
        // const chartConfig = {
        //     type: 'bar',
        //     data: {
        //         labels: xLabels,
        //         datasets: [
        //             {
        //                 label: '0-25 %',
        //                 data: percentageData,
        //                 categoryPercentage: 0.8,
        //                 barPercentage: 0.5,
        //                 backgroundColor: gradientForO_to_3,
        //                 datalabels: {
        //                     display: true,
        //                     anchor: 'end',
        //                     align: 'end',
        //                     color: '#dee5ef',
        //                     font: {
        //                         size: 20
        //                     }
        //                 },
        //             },
        //         ]
        //     },
        //     options: {
        //         layout: {
        //             padding: {
        //                 left: 0,
        //                 right: 0,
        //                 top: 30,
        //                 bottom: 0
        //             }
        //         },
        //         responsive: true,
        //         maintainAspectRatio: false,
        //         scales: {
        //             xAxes: [
        //                 {
        //                     scaleLabel: {
        //                         display: true,
        //                         labelString: 'Percentage Completion',
        //                         fontColor: '#dee5ef',
        //                         fontSize: 24,
        //                     },
        //                     stacked: true,
        //                     type: 'category',
        //                     display: true,
        //                     offset: true,
        //                     gridLines: {
        //                         color: 'transparent',
        //                         offsetGidLines: true,
        //                         tickMarkLength: 0,
        //                     },
        //                     ticks: {
        //                         display: true,
        //                         padding: 9,
        //                         fontColor: '#dee5ef',
        //                         fontSize: '20',
        //                     },
        //                 },
        //             ],

        //             yAxes: [
        //                 {
        //                     scaleLabel: {
        //                         display: true,
        //                         labelString: 'Ongoing projects',
        //                         fontColor: '#dee5ef',
        //                         fontSize: 24,
        //                     },
        //                     stacked: true,
        //                     beginAtZero: true,
        //                     ticks: {
        //                         beginAtZero: true,
        //                         display: true,
        //                         padding: 20,
        //                         fontColor: '#dee5ef',
        //                         fontSize: '20',
        //                         maxTicksLimit : 4
        //                     },
        //                     gridLines: {
        //                         color: '#2f3b51',
        //                         tickMarkLength: 0,
        //                         zeroLineColor: '#2f3b51',
        //                         zeroLineWidth: 1
        //                     }
        //                 },
        //                 {
        //                     scaleLabel: {
        //                         display: false,//currently not required
        //                         fontColor: '#64779c',
        //                         fontSize: 24,
        //                     },
        //                     stacked: true,
        //                     display: false,//currently not required
        //                     position: 'right',
        //                     // beginAtZero: true,
        //                     ticks: {
        //                         maxTicksLimit : 4,
        //                         beginAtZero: true,
        //                         display: true,
        //                         padding: 20,
        //                         fontColor: '#dee5ef',
        //                         fontSize: '20',
        //                     },
        //                     gridLines: {
        //                         color: '#2f3b51',
        //                         tickMarkLength: 0,
        //                         zeroLineColor: '#2f3b51',
        //                         zeroLineWidth: 1
        //                     }
        //                 },
        //             ]
        //         },
        //         legend: {
        //             display: false
        //         },
        //     }
        // }

        let chartConfig = {
            type: 'bar',
            data: {
                labels: ['CFU Depot Jammu', 'CFU Depot Sadashibpur'],
                datasets: [
                    {
                        label: 'FY 2020',
                        stack: 3,
                        yAxisID: 'bar-y-axis1',
                        xAxisID: 'bar-x-axis1',
                        backgroundColor: gradientForProjectCost,
                        borderWidth: 0.5,
                        // categoryPercentage: 0.8,
                        // barPercentage: 0.4,
                        data: ['677', '394', '325'],
                        datalabels : {
                            display : true,
                            anchor: 'end',
                            align: 'end',
                            color: '#dee5ef',
                            font: {
                                size: 20
                            }
                        }
                    },
                    {
                        label: 'FY 2019',
                        stack: 2,
                        yAxisID: 'bar-y-axis1',
                        xAxisID: 'bar-x-axis1',
                        backgroundColor: gradientForTotalExpenses,
                        borderWidth: 0.5,
                        data: ['144', '27', '236'],
                        datalabels : {
                            display : true,
                            anchor: 'end',
                            align: 'end',
                            color: '#dee5ef',
                            font: {
                                size: 20
                            }
                        }
                    },
                    {
                        label: 'FY 2019',
                        stack: 1,
                        yAxisID: 'bar-y-axis1',
                        xAxisID: 'bar-x-axis1',
                        backgroundColor: gradientForCompletion,
                        borderWidth: 0.5,
                        data: ['14', '14', '99'],
                        datalabels : {
                            display : true,
                            anchor: 'end',
                            align: 'end',
                            color: '#dee5ef',
                            font: {
                                size: 20
                            }
                        }
                    },
                    //check AGRM bar
                    // {
                    //   label: "American Express",
                    //   stack: 2,
                    //   yAxisID: "bar-y-axis1",
                    //   xAxisID: "bar-x-axis1",
                    //   backgroundColor: ["#6849ba", "#6849ba", "#6849ba", "#6849ba"],
                    //   borderWidth: 1,
                    //   categoryPercentage: 0.8,
                    //   barPercentage: 0.4,
                    //   data: props.kpiName === 'GRM'? fycurrent : [2,2,2,2],
                    //   //data: agrmData,
                    //   datalabels : {
                    //     display : false
                    //   }
                    //},
                    //check
                ],
            },
            options: {
                legend: {
                    display: false,
                },
                layout: {
                    padding: {
                        left: 20
                    }
                },
                responsive: true,
                maintainAspectRatio: false,
                tooltips : {
                    enabled : true,
                    mode : 'label',
                    callbacks: {
                        label: function(tooltipItems, data) {
                            return data.datasets[tooltipItems.datasetIndex].data[tooltipItems.index] + ' '
                        }
                    }
                },
                scales: {
                    xAxes: [
                        {
                            id: 'bar-x-axis1',
                            stacked: true,
                            type: 'category',
                            display: true,
                            offset: true,
                            gridLines: {
                                color: 'transparent',
                                offsetGidLines: true,
                                tickMarkLength: 0,
                                borderDash: [3],
                            },
                            ticks: {
                                display: true,
                                padding: 9,
                                fontColor: '#dee5ef',
                                fontSize: '20',
                            },
                        },
                    ],

                    yAxes: [
                        {
                            // display: targetcurrent.every(item => item === 0),
                            id: 'bar-y-axis1',
                            stacked: true,
                            beginAtZero: true,
                            scaleLabel: {
                                display: true,
                                // labelString: unit[0],
                                fontColor: '#dee5ef',
                                fontSize: '20',
                            },
                            ticks: {
                                beginAtZero: true,
                                display: true,
                                padding: 20,
                                fontColor: '#dee5ef',
                                fontSize: '20',
                                // min : 0,
                                // max : Math.round(Math.max(...maxvalue))+1,
                                // stepSize :  Math.round(Math.max(...maxvalue)/4)
                                maxTicksLimit : 5
                            },
                            gridLines: {
                                color: '#2f3b51',
                                tickMarkLength: 0,
                                zeroLineColor: '#67758d',
                                zeroLineWidth: 1
                            }
                        },
                    ]
                },
            },
        }

        return chartConfig
    }

  
    useEffect(() => {
        if (chartInstance) {
            chartInstance.destroy()
        }

        const ctx = chartContainer.current.getContext('2d')
        const chartConfig = getChartConfig()

        if (chartContainer && chartContainer.current) {
            const newChartInstance = new Chart(ctx, chartConfig)
            setChartInstance(newChartInstance)
        }
    }, [chartContainer, graphData, estdCompletionFilters]) //eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className={`d-flex ${classes.BuProjectsPlanningGraph}`}>
            <div className='col'>
                <canvas ref={chartContainer} height={400} width={1020} />
            </div>
        </div>
    )
}
export default BuProjectsRetailPlanningGraphCount