import axios from 'axios'
import { withAuthorizationHeaders } from '../components/Authentication/services/authHeaders'
import { ORG_KPI_URL, PLANNING_KPI_URL } from '../environment'

export const fetchFinanceGrowthGrm = async (dataType, year) => {
    const res = await axios.get(
        `${ORG_KPI_URL}/financedatagrowth?data_type=${dataType.toLowerCase()}&kpi_type=growth&sub_kpi_type=GRM&year=${year}`,
        { withCredentials: true }
    )
    return res.data
}

export const fetchFinanceGrowthThroughput = async (dataType, year) => {
    const res = await axios.get(
        `${ORG_KPI_URL}/financedatagrowth?data_type=${dataType.toLowerCase()}&kpi_type=growth&sub_kpi_type=throughput&year=${year}`,
        { withCredentials: true }
    )
    return res.data
}

export const fetchFinanceGrowthCapex = async (dataType, year) => {
    const res = await axios.get(
        `${ORG_KPI_URL}/financedatagrowth?data_type=${dataType.toLowerCase()}&kpi_type=growth&sub_kpi_type=capex&year=${year}`,
        { withCredentials: true }
    )
    return res.data
}

export const fetchFinanceGrowthSales = async (dataType, year) => {
    const res = await axios.get(
        `${ORG_KPI_URL}/financedatagrowthsales?data_type=${dataType.toLowerCase()}&kpi_type=growth&sub_kpi_type=sales&year=${year}`,
        { withCredentials: true }
    )
    return res.data
}

export const fetchStmarketDataYear = async (startYear) => {
    const res = await axios.get(
        `${ORG_KPI_URL}/stmarket?startYear=${startYear}&datatype=YTM`,
        { withCredentials: true }
    )
    return res.data
}

export const fetchSensexDataYear = async (startYear) => {
    const res = await axios.get(
        `${ORG_KPI_URL}/sensex?startYear=${startYear}&datatype=YTM`,
        { withCredentials: true }
    )
    return res.data
}

export const fetchNiftyDataYear = async (startYear) => {
    const res = await axios.get(
        `${ORG_KPI_URL}/nifty?startYear=${startYear}&datatype=YTM`,
        { withCredentials: true }
    )
    return res.data
}

export const fetchStmarketDataMonth = async (startMonth, startYear) => {
    const res = await axios.get(
        `${ORG_KPI_URL}/stmarket?startMonth=${startMonth}&startYear=${startYear}&datatype=MTD`,
        { withCredentials: true }
    )
    return res.data
}

export const fetchSensexDataMonth = async (startMonth, startYear) => {
    const res = await axios.get(
        `${ORG_KPI_URL}/sensex?startMonth=${startMonth}&startYear=${startYear}&datatype=MTD`,
        { withCredentials: true }
    )
    return res.data
}

export const fetchNiftyDataMonth = async (startMonth, startYear) => {
    const res = await axios.get(
        `${ORG_KPI_URL}/nifty?startMonth=${startMonth}&startYear=${startYear}&datatype=MTD`,
        { withCredentials: true }
    )
    return res.data
}

export const fetchStMarketInfo = async () => {
    let url = `${ORG_KPI_URL}/stmarketinfo`
    const res = await axios.get(url, { withCredentials: true })
    return res.data
}

export const fetchTreasuryBorrowing = async (dataType, year) => {
    const res = await axios.get(
        `${ORG_KPI_URL}/financedatamarket?data_type=${dataType.toLowerCase()}&kpi_type=treasury&sub_kpi_type=borrowing&year=${year}`,
        { withCredentials: true }
    )
    return res.data
}

export const fetchTreasuryroce = async (dataType, year) => {
    const res = await axios.get(
        `${ORG_KPI_URL}/financedatamarket?data_type=${dataType.toLowerCase()}&kpi_type=treasury&sub_kpi_type=roce&year=${year}`,
        { withCredentials: true }
    )
    return res.data
}

export const fetchTresuryCRmf = async (dataType, year) => {
    const res = await axios.get(
        `${ORG_KPI_URL}/financedatamarket?data_type=${dataType.toLowerCase()}&kpi_type=treasury&sub_kpi_type=crmoody&year=${year}`,
        { withCredentials: true }
    )
    return res.data
}

export const fetchTresuryCRCrisil = async (dataType, year) => {
    const res = await axios.get(
        `${ORG_KPI_URL}/financedatamarket?data_type=${dataType.toLowerCase()}&kpi_type=treasury&sub_kpi_type=crcrisil&year=${year}`,
        { withCredentials: true }
    )
    return res.data
}

export const fetchTreasuryDm = async (dataType, year) => {
    const res = await axios.get(
        `${ORG_KPI_URL}/treasurydata?data_type=${dataType.toLowerCase()}&kpi_type=treasury&year=${year}`,
        { withCredentials: true }
    )
    return res.data
    
}

export const fetchFinancialRatio = async (dataType, year) => {
    const res = await axios.get(
        `${ORG_KPI_URL}/financedata?data_type=${dataType.toLowerCase()}&kpi_type=financeratio&year=${year}`,
        { withCredentials: true }
    )
    return res.data
}

export const fetchProfitLoss = async (dataType, year) => {
    const res = await axios.get(
        `${ORG_KPI_URL}/financedata?data_type=${dataType.toLowerCase()}&kpi_type=profitloss&year=${year}`,
        { withCredentials: true }
    )
    return res.data
}

export const fetchTotalCapex = async (dataType, year) => {
    const res = await axios.get(
        `${ORG_KPI_URL}/financedatagrowth?data_type=${dataType.toLowerCase()}&kpi_type=growth&sub_kpi_type=capex&year=${year}`,
        { withCredentials: true }
    )
    return res.data
}

export const fetchEmployeeDetailsDemographic = async (year) => {
    let sectionName = "Employee Data And Recruitment";
    // Encode the URL parameter to ensure safe transmission
    let encodedSectionName = sectionName.replace(/ /g, "+");
    let urlemp = `${ORG_KPI_URL}/employeedetails?kpiname=hr&sectionname=${encodedSectionName}&year=${year}&sub_kpi_type=employeedemographic`
    const res = await axios.get(urlemp, { withCredentials: true })
    return res.data
}

export const fetchEmployeeDetailsData = async (year) => {
    let sectionName = "Employee Data And Recruitment";
    // Encode the URL parameter to ensure safe transmission
    let encodedSectionName = sectionName.replace(/ /g, "+");
    let urlmft = `${ORG_KPI_URL}/employeedetails?kpiname=hr&sectionname=${encodedSectionName}&year=${year}&sub_kpi_type=employeedata`
    const res = await axios.get(urlmft, { withCredentials: true })
    return res.data
}

export const fetchEthicalHr = async (year) => {
    const res = await axios.get(
        `${ORG_KPI_URL}/employeeDist?kpi_name=ehr&year=${year}`,
        { withCredentials: true }
    )
    return res.data
}

export const fetchLearningDevelopment = async (dataType, year) => {
    const res = await axios.get(
        `${ORG_KPI_URL}/hrdata?data_type=${dataType.toLowerCase()}&kpi_type=training&year=${year}`,
        { withCredentials: true }
    )
    return res.data
}

export const fetchPerformanceProductivity = async (dataType, year) => {
    const res = await axios.get(
        `${ORG_KPI_URL}/financedata?data_type=${dataType.toLowerCase()}&kpi_type=pp&year=${year}`,
        { withCredentials: true }
    )
    return res.data
}

export const fetchOrgHsseIncident = async (dataType, year) => {
    let kpiName = "Incidents Reported";
    // Encode the URL parameter to ensure safe transmission
    let encodedKpiName = kpiName.replace(/ /g, "+");
    const res = await axios.get(
        `${ORG_KPI_URL}/hsseincident?data_type=${dataType.toLowerCase()}&kpi_name=${encodedKpiName}&year=${year}`,
        { withCredentials: true }
    )
    return res.data
}

export const fetchOrgHsseData = async (dataType, year) => {
    const res = await axios.get(
        `${ORG_KPI_URL}/hssedata?data_type=${dataType.toLowerCase()}&kpi_type=hsse&year=${year}`,
        { withCredentials: true }
    )
    return res.data
}

export const fetchOrgNews = async () => {
    let url = `${ORG_KPI_URL}/bpclnews`
    const res = await axios.get(url, { withCredentials: true })
    return res.data
}

export const fetchBalanceSheetOil = async (dataType, year) => {
    const res = await axios.get(
        `${ORG_KPI_URL}/financedatamarket?data_type=${dataType.toLowerCase()}&kpi_type=balancesheet&sub_kpi_type=oilbond&year=${year}`,
        { withCredentials: true }
    )
    return res.data
}

export const fetchBalanceSheetCapital = async (dataType, year) => {
    const res = await axios.get(
        `${ORG_KPI_URL}/financedatamarket?data_type=${dataType.toLowerCase()}&kpi_type=balancesheet&sub_kpi_type=capitalemployed&year=${year}`,
        { withCredentials: true }
    )
    return res.data
}

export const fetchBalancesheetDm = async (dataType, year) => {
    const res = await axios.get(
        `${ORG_KPI_URL}/financedata?data_type=${dataType.toLowerCase()}&kpi_type=balancesheet&year=${year}`,
        { withCredentials: true }
    )
    return res.data
}

export const fetchOrgLanding = async () => {
    const res = await axios.get(
        `${ORG_KPI_URL}/orgyr`,
        { withCredentials: true }
    )
    return res.data
}

export const fetchupdatedStatus = async (year, updatedBU) => {
    // Encode the URL parameter to ensure safe transmission
    let encodedSectionName = updatedBU.replace(/ /g, "+");
    let BU = encodedSectionName !== undefined ? encodedSectionName : "ORGANIZATION"
    let url = `${PLANNING_KPI_URL}/getUpdatedStatus?year=${year}&bu=${BU}`
    const res = await axios.get(url, { withCredentials: true })
    return res.data
}
