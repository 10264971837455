import styles from './BuProjects.module.scss'
import React from 'react'
import BuProjectsPlannedFYChart from './BuProjectsPlannedFYChart'
import BuProjectsPlanningGraph from './BuProjectsPlanningGraph'
import BuProjectsPlanningGraphCount from './BuProjectsPlanningGraphCount'
import BuProjectsRetailPlanningGraphCount from './BuProjectsRetailPlanningGraphCount'

import expandIcon from '../../../assets/images/expandIcon.png'

const BuProjectPlanningTab = ({ graphData, fullScrn, year, businessUnit }) => (
    <div className={`d-flex flex-column justify-content-between ${styles.graphContainer}`}>
        <div className='row justify-content-center'>
            {year >= '2023' && businessUnit === "lpg_tab" ?
                <BuProjectsPlanningGraphCount graphData={graphData} />
                :  year >= '2023' && businessUnit === "retail_tab" ?
                    <BuProjectsRetailPlanningGraphCount graphData={graphData} />
                    :
                    <BuProjectsPlanningGraph graphData={graphData} />
}
        </div>
        <div className={styles.hr}  style={{marginBottom: 10,  marginTop: 10}}></div>
        <div className='row justify-content-center'>
        {year >= '2023'?'':
            <BuProjectsPlannedFYChart graphData={graphData} />
}
        </div>
        <div className='d-flex justify-content-end'
            style={{
                bottom: 64,
                position: 'relative',
                left: 32
            }}
        >
            <img src={expandIcon} alt='Expand Icon' id='fullBup' data-fs='handleenter' onClick={fullScrn}></img>
        </div>
    </div>
)
export default BuProjectPlanningTab