import React, { useState, useEffect } from 'react'
import Chart from 'react-google-charts'
import BackButton from './BackButton'
import CountryChart from './CountryChart'
import styles from './Map.module.scss'
import ChartData from './ChartData'
import LegendsProgressBar from './LegendsProgressBar'
import { fetchItrmRegionData, fetchupdatedStatus } from '../../services/Itrm'
import MapTable from './Table'
import { isEmpty } from 'lodash'
import { NoDataFound } from '../../components/DataNotFound/nodatafound'
import greenTick from '../../assets/images/greenTick.png'

const GoogleChart = (props) => {
    let mounted = true
    const [data, setData] = useState([])
    const [chartData, setChartData] = useState([])
    const [hsData, setHsData] = useState([])
    const [lsData, setLsData] = useState([])
    const [loading, setLoading] = useState(false)
    const [selectBoxMode, setSelectBoxMode] = useState('ytm')
    const [location, setLocation] = useState(props.location)
    const [TotalPer, setTotalPer] = useState(0)
    const [updatedStatus, setUpdatedStatus] = useState('')

    const [childData, setChildData] = useState({
        clicked: false,
        regionClicked: {},
    })

    const mapClickHandler = (e, region) => {
        if (data[region + 1]) {
            let fetchedRegionName = data[region + 1][0]
            chartData.map((item) => {
                item.countries.map((el) => {
                    if (el.v === fetchedRegionName) {
                        //console.debug(item);
                        props.getRegionNameOnClick(item.region.toLowerCase())
                        setChildData({
                            clicked: !clicked,
                            regionClicked: item,
                        })
                    }
                })
            })
        }
    }
    const legendClickHandler = (regionData) => {
        props.getRegionNameOnClick(regionData.region.toLowerCase())
        setChildData({
            clicked: !clicked,
            regionClicked: regionData,
        })
    }
    let temp = []
    const storeCountry = (
        countryList,
        percent,
        region,
        regionCode,
        term_hs,
        term_ls,
        spot_hs,
        spot_ls,
        indigenous_hs,
        indigenous_ls,
    ) => {
        countryList.map((country) => {
            temp.push([country.v, percent])
            setData([['Country', 'Percent'], ...temp])
        })

        setChartData((state) => [
            ...state,
            {
                countries: countryList,
                overall_percentage: percent,
                region: region,
                regionCode: regionCode,
                term_hs,
                spot_hs,
                indigenous_hs,
                term_ls,
                spot_ls,
                indigenous_ls,
            },
        ])
    }
    useEffect(() => {
        const staticLocation = props.location === "mumbai" ? "Mumbai" : props.location === "kochi" ? "Kochi" : props.location === "bina" ? "Bina" : "All"
        fetchupdatedStatus(props.year)
        .then(response => {
            if (!isEmpty(response)) {
                response.filter((res) => res.entity === staticLocation && res.kpi_name === "ITRM Map" ? setUpdatedStatus(res.status) : false);
            } else {
                setUpdatedStatus("Not Found")
            }
        })
        .catch(error => console.debug(error))
        let mounted = true
            fetchItrmRegionData(props.location, selectBoxMode, props.year)
                .then((response) => {
                    if(mounted){
                    if(isEmpty(response.data)){
                        setChartData([])
                        setData([])
                        setLoading(false)
                    }else{
                        const regionMap = {}
                        const objByCountry = {};

                        for (let i = 0, len = response.data.length; i < len; i++) {
                            objByCountry[response.data[i]['country']] = response.data[i];
                        }

                        const arrByCountry = new Array();

                        for (const key in objByCountry) {
                            arrByCountry.push(objByCountry[key]);
                        }

                        let groupByRegion = arrByCountry.reduce((r, a) => {
                            r[a.region] = [...r[a.region] || [], a];
                            return r;
                        }, {});
                        response.data.forEach((el) => {
                            if(el.region !== null) {
                                regionMap[el.region] = el
                                setLoading(true)
                            } else {
                                // regionMap[el.region] = {}    
                                setLoading(false)
                            }
                        })
                        // mapping table data term hs
                        let fetchTableDataths =  Object.keys(groupByRegion).map((regionName) => {
                            const el = groupByRegion[regionName]
                            const sumOfTermHs = el.reduce((x, object) => {
                                return x + object.term_hs;
                              }, 0);
                            return sumOfTermHs
                        })
                        let term_hs_procurred = fetchTableDataths.reduce((a,b) => {return a + b}, 0)
                        // mapping table data term ls

                        let fetchTableDatatls = Object.keys(groupByRegion).map((regionName) => {
                            const el = groupByRegion[regionName]
                            const sumOfTermLs = el.reduce((x, object) => {
                                return x + object.term_ls;
                              }, 0);
                            return sumOfTermLs
                    })
                        let term_ls_procurred = fetchTableDatatls.reduce((a,b) => {return a + b}, 0)
                        // mapping table data spot hs
                        let fetchTableDatashs = Object.keys(groupByRegion).map((regionName) => {
                            const el = groupByRegion[regionName]
                            const sumOfSpotHs = el.reduce((x, object) => {
                                return x + object.spot_hs;
                              }, 0);
                            return sumOfSpotHs
                    })
                        let spot_hs_procurred = fetchTableDatashs.reduce((a,b) => {return a + b}, 0)
                        // mapping table data spot ls
                        let fetchTableDatasls = Object.keys(groupByRegion).map((regionName) => {
                            const el = groupByRegion[regionName]
                            const sumOfSpotLs = el.reduce((x, object) => {
                                return x + object.spot_ls;
                              }, 0);
                            return sumOfSpotLs
                    })
                        let spot_ls_procurred = fetchTableDatasls.reduce((a,b) => {return a + b}, 0)
                        // mapping table data indigenous hs
                        let fetchTableDataihs = Object.keys(groupByRegion).map((regionName) => {
                            const el = groupByRegion[regionName]
                            const sumOfIndigenousHs = el.reduce((x, object) => {
                                return x + object.indigenous_hs;
                              }, 0);
                            return sumOfIndigenousHs
                    })
                        let indigenous_hs_procurred = fetchTableDataihs.reduce((a,b) => {return a + b}, 0)
                        // mapping table data indigenous ls
                        let fetchTableDatails = Object.keys(groupByRegion).map((regionName) => {
                            const el = groupByRegion[regionName]
                            const sumOfIndigenousLs = el.reduce((x, object) => {
                                return x + object.indigenous_ls;
                              }, 0);
                            return sumOfIndigenousLs
                    })
                        let indigenous_ls_procurred = fetchTableDatails.reduce((a,b) => {return a + b}, 0)
                        let total_hs_procur = term_hs_procurred + spot_hs_procurred + indigenous_hs_procurred
                        let total_ls_procur = term_ls_procurred + spot_ls_procurred + indigenous_ls_procurred
                        let total = total_ls_procur + total_hs_procur
                        setTotalPer(total)
                        Object.keys(groupByRegion).forEach((regionName) => {
                            const el = groupByRegion[regionName]
                            const term_hs = el.reduce((x, object) => {
                                return x + object.term_hs;
                              }, 0);
                              const term_ls = el.reduce((x, object) => {
                                return x + object.term_ls;
                              }, 0);
                              const spot_hs = el.reduce((x, object) => {
                                return x + object.spot_hs;
                              }, 0);
                              const spot_ls = el.reduce((x, object) => {
                                return x + object.spot_ls;
                              }, 0);
                              const indigenous_hs = el.reduce((x, object) => {
                                return x + object.indigenous_hs;
                              }, 0);
                              const indigenous_ls = el.reduce((x, object) => {
                                return x + object.indigenous_ls;
                              }, 0);
                              const country_list = el[0].country_list
                              const region = el[0].region
                              const regionCode = el[0].regionCode
                             const percentage = ((Number(term_hs) + Number(term_ls) + Number(spot_hs) + Number(spot_ls) + Number(indigenous_hs) + Number(indigenous_ls)) / Number(total)) * 100
                            storeCountry(
                                country_list,
                                percentage,
                                region,
                                regionCode,
                                term_hs,
                                term_ls,
                                spot_hs,
                                spot_ls,
                                indigenous_hs,
                                indigenous_ls,
                            )
                        })
                    }
                    }
                })
            .catch((error) => console.debug(error))    
        setLocation(props.location)
        setChartData([])
        return () => {
            mounted = false
        }
    }, [selectBoxMode, props.location, props.year]) // eslint-disable-line react-hooks/exhaustive-deps

    const backClickHandler = (e, region) => {
        setChildData({ clicked: !clicked })
    }
    const chartEvents = [
        {
            eventName: 'ready',
            callback: ({ chartWrapper, google }) => {
                const chart = chartWrapper.getChart()
                chart.container.addEventListener('click', (ev) => {
                    if (!clicked) {
                        const indexOfSelectedRegion =
                            chart.getSelection().length > 0
                                ? chart.getSelection()[0]['row']
                                : null
                        if (indexOfSelectedRegion) {
                            mapClickHandler(ev, indexOfSelectedRegion)
                        } else {
                            return false
                        }
                    }
                })
            },
        },
    ]
    const { clicked, regionClicked } = childData

    const getSelectBoxMode = (val) => {
        setSelectBoxMode(val)
    }
    return (
        <div>
            {clicked ? (
                <div>
                    <BackButton backClickHandler={() => backClickHandler()} />
                    <CountryChart totalper={TotalPer} year={props.year} location={props.location} regionClicked={regionClicked}></CountryChart>
                </div>
            ) : loading ? (
                <div style={{ position: 'relative' }}>
                    <div className={`${styles.crudeProcurementHeading}`}>
                        Crude Oil Procurement - YTM (MMT) - Monthly &nbsp; &nbsp;
                        {(updatedStatus === "Updated") &&
                        <img src={greenTick} className="greenTik" alt='Expand Icon'></img>
                    }
                    </div>
                    <Chart
                        width={'100%'}
                        chartType='GeoChart'
                        chartEvents={chartEvents}
                        options={{
                            colorAxis: {
                                colors: [
                                    '#006aff',
                                    '#00aaff',
                                    '#00ddff',
                                    '#00ffff',
                                    '00ffcc',
                                ],
                            },
                            backgroundColor: '#000000',
                            datalessRegionColor: '#283040',
                            defaultColor: '#000000',
                            stroke: '#6699cc',
                            legend: 'none',
                            // legend: {
                            //     textStyle: {
                            //         color: 'red',
                            //         fontSize: 30
                            //     }
                            // },
                            tooltip: { trigger: 'none' },
                        }}
                        data={data}
                        //mapsApiKey={process.env.REACT_APP_GOOGLE_KEY}
                        style={{
                            transform: `scale(${0.8})`,
                            position: 'absolute',
                            right: '100px',
                            cursor: 'pointer',
                            // bottom: '-545px'
                        }}
                    />
                    {/* <div className={`${styles.selectbox}`}>
                        <SelectBox getSelectBoxMode={(val) => getSelectBoxMode(val)} />
                    </div> */}
                    <LegendsProgressBar />
                    <ChartData year={props.year} legendClickHandler={legendClickHandler} chartData={chartData} location={location} />

                    <div className={`${styles.mapTable}`}>
                        <div className={`${styles.table}`}>
                            <MapTable data={chartData} hsData={hsData} lsData={lsData} year={props.year} regionName='all regions' location={location} />
                        </div>
                    </div>
                </div>
            ) : (
                <div className={`d-flex justify-content-center ${styles.worldmapcontainer}`}>
                    <NoDataFound />
                </div>
            )}
        </div>
    )
}
export default GoogleChart
