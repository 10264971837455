import React, { useState, useEffect, useCallback } from 'react'
import styles from './FinanceTreasurey.module.scss'
import ExpandIcon from '../../../assets/images/expandIcon.png'
//import OrgListFT from './OrgListFT'
import ForeignCurrency from './ForeignCurrency/ForeignCurrency'
import InternationalBond from './InternationalBond/InternationalBond'
import { FullScreen, useFullScreenHandle } from 'react-full-screen'
import { fetchTreasuryDm, fetchupdatedStatus } from '../../../services/Org'
import { NoDataFound } from '../../DataNotFound/nodatafound'
import { QuarterlyDataNotFound } from '../../DataNotFound/quarterlydatanotfound'
import { isEmpty } from 'lodash'
import greenTick from '../../../assets/images/greenTick.png'
import "../../../Common.scss"

const FinanceTreasurey = (props) => {
    const [tdata, settdata] = useState([])
    const [totalforgn, settotalforgn] = useState([])
    const [totalint, settotalint] = useState([])
    const [loading, setLoading] = useState(true)
    const [loadingTreas, setLoadingTreas] = useState(true)
    const handle = useFullScreenHandle()
    const [attr, setattr] = useState('handleenter')
    const [updatedStatus, setUpdatedStatus] = useState('')

    const fullScrn = () => {
        if (attr === 'handleenter') {
            setattr('handleexit')
            handle.enter()
            document.body.style.zoom = (window.innerWidth / window.outerWidth)
        } else if (attr === 'handleexit') {
            setattr('handleenter')
            handle.exit()
            document.body.style.zoom = 0
        }
    }
    const reportChange = useCallback((state) => {
        try{
            if(state === false){
                document.body.style.zoom = 0
            }
        }catch(exception){
            console.debug(exception)
        }
      }, [handle]);
    useEffect(() => {
        fetchupdatedStatus(props.year)
            .then(response => {
                if (!isEmpty(response)) {
                    response.filter((res) => res.entity === "Finance" && res.kpi_name === "long term borrowing" ? setUpdatedStatus(res.status) : false);
                } else {
                    setUpdatedStatus("Not Found")
                }
            })
            .catch(error => console.debug(error))
        function getTreasurydm() {
            let totaldebt = []
            let totalmat = []
            fetchTreasuryDm(props.dropdown.toLowerCase(), props.year).then(data => {
                if (data.length === 0) {
                    setLoading(true)
                    setLoadingTreas(true)
                } else {
                    data.forEach(gettotal => {
                        if (gettotal.sub_kpi_type === 'foreign') {
                            totaldebt.push(gettotal.value)
                            let totald = totaldebt[0]
                            settotalforgn(totald)
                            setLoading(false)
                        } else if (gettotal.sub_kpi_type === 'domestic') {
                            totalmat.push(gettotal.value)
                            let totalm = totalmat[0]
                            settotalint(totalm)
                            setLoadingTreas(false)
                        }
                    })
                    settdata(data.reverse())
                }
            })
        }
        getTreasurydm()
    }, [props.dropdown, props.year])
    return (
        <div className={`${styles.treasureyContainer}`}>
            <FullScreen handle={handle} onChange={reportChange}>
                <div className={`d-flex align-items-center justify-content-between ${styles.treasureyHeader}`}>
                    <span>Long Term Borrowings - Quarterly</span>
                    {(updatedStatus === "Updated") && <div className={`${styles.statusUpdatedImg}`}>
                        <img src={greenTick} className="greenTik" alt='Expand Icon'></img>
                    </div>}
                    <img src={ExpandIcon} alt="Expand Treasurey KPIs" id="fullSrnft" data-fs="handleenter" onClick={fullScrn}></img>
                </div>
                <div className={`${styles.foreignCurrencyLoanGraphContainer}`}>
                    {loading ?
                        // <NoDataFound />
                        <QuarterlyDataNotFound />
                        :
                        <ForeignCurrency total={totalforgn} graphData={tdata} year={props.year}></ForeignCurrency>
                    }
                </div>
                <div className={`${styles.internationalBondGraphContainer}`}>
                    {loadingTreas ?
                        // <NoDataFound />
                        <QuarterlyDataNotFound />
                        :
                        <InternationalBond total={totalint} graphData={tdata} year={props.year}></InternationalBond>
                    }
                </div>
            </FullScreen>
            {/* <OrgListFT borrow={borrow} crdcsl={crdcsl} roce={roce} crd={crd}></OrgListFT> */}
        </div>
    )
}
export default FinanceTreasurey