import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import styles from "./BuLanding.module.scss";
import BuRetailMapContainer from "../../components/BuRetailMapContainer/BuRetailMapContainer";
import BuLubesMapContainer from "../../components/BuLubesMapContainer/BuLubesMapContainer";
import BuLpgMapContainer from "../../components/BuLpgMapContainer/BuLpgMapContainer";
import BuGraphs from "../../components/BuRetailGraphs/BuGraphs";
import BuLpgGraphs from "../../components/BuLpgGraphs/BuLpgGraphs";
import BuLubesGraphs from "../../components/BuLubesGraphs/BuLubesGraphs";
import BuAtfGraphs from "../../components/BuAtfGraphs/BuAtfGraphs";
import BuAtfMapContainer from "../../components/BuAtfMapContainer/BuAtfMapContainer";
import BuIncGraphs from "../../components/BuIncGraphs/BuIncGraphs";
import BuIncMapContainer from "../../components/BuIncMapContainer/BuIncMapContainer";
import { Tab, TabPanel, Tabs, TabList } from "react-web-tabs";
import BuGraphsProject from "../../components/BuProjectGraphs/BuGraphs";

import "react-web-tabs/dist/react-web-tabs.css";
import {
  fetchMenuData,
  fetchBuLanding,
  fetchRetailHsse,
} from "../../services/bu";
import { numberFormatEnIn } from "../../utility/utility";
import { Dropdown } from "react-bootstrap";
import dropdownArrows from "../../assets/images/dropdownArrows.png";
import { isEmpty } from "lodash";
import itEcosystem from "../../assets/images/System_Architecture_Flowchart_19Oct2023.mp4";
// import redicon from '../../assets/images/homepage/bu/redicon.icon';
import bularge from '../../assets/images/homepage/bu/bularge.png'

const BuLanding = () => {
  document.title = "Business Unit | BPCL";
  const [mapOf, setMapOf] = useState("all regions");
  const [mapCenter, setMapCenter] = useState({ lat: 18, lng: 80 });
  const [mapZoom, setMapZoom] = useState(5.65);
  const [activeTab, setActiveTab] = useState("retail_tab");
  const [retail, setretail] = useState([]);
  const [lpg, setlpg] = useState([]);
  const [atf, setatf] = useState([]);
  const [inc, setinc] = useState([]);
  const [lubes, setlubes] = useState([]);
  const [overall, setoverall] = useState([]);
  const [year, setyear] = useState("2020");
  // const [yearPrev, setyearPrev] = useState('2019')
  const [yearNext, setyearNext] = useState("2021");
  const [yearCurrent, setyearCurrent] = useState("2020");
  const [lastUpdated, setLastUpdated] = useState([]);
  const [location, setLocation] = useState("");

  function handleRegionDropdown(mapRegion, mapCenter, mapZoom) {
    setMapOf(mapRegion);
    setMapCenter(mapCenter);
    setMapZoom(mapZoom);
  }
  useEffect(() => {
    fetchBuLanding()
      .then((data) => {
        if (data.length === 0) {
          setyear("2020");
        } else {
          setyear(data.year.toString());
          //setyearPrev((data.year - 1).toString())
          setyearNext(data.current_finance_year.toString());
          if (data.year === data.current_finance_year) {
            setyearCurrent((data.year - 1).toString());
          } else {
            setyearCurrent(data.year.toString());
          }
        }
      })
      .catch((error) => console.debug(error));
  }, []);
  useEffect(() => {
    let ret_array = [];
    let lpg_array = [];
    let inc_array = [];
    let atf_array = [];
    let lubes_array = [];
    let overall_array = [];
    fetchMenuData(year)
      .then((response) => {
        if (response.data.length > 0) {
          response.data.forEach((getMenu) => {
            if (getMenu.kpi_type === "retail") {
              ret_array.push(getMenu);
              setretail(ret_array[ret_array.length - 1]);
            } else if (getMenu.kpi_type === "lpg") {
              lpg_array.push(getMenu);
              setlpg(lpg_array[lpg_array.length - 1]);
            } else if (getMenu.kpi_type === "atf") {
              atf_array.push(getMenu);
              setatf(atf_array[atf_array.length - 1]);
            } else if (getMenu.kpi_type === "inc") {
              inc_array.push(getMenu);
              setinc(inc_array[inc_array.length - 1]);
            } else if (getMenu.kpi_type === "lubes") {
              lubes_array.push(getMenu);
              setlubes(lubes_array[lubes_array.length - 1]);
            } else if (getMenu.kpi_type === "overall") {
              overall_array.push(getMenu);
              setoverall(overall_array[overall_array.length - 1]);
            }
          });
        } else {
          setretail({ sales: 0, revenue: 0, marketshare: 0, hsse: 0 });
          setlpg({ sales: 0, revenue: 0, marketshare: 0, hsse: 0 });
          setatf({ sales: 0, revenue: 0, marketshare: 0, hsse: 0 });
          setinc({ sales: 0, revenue: 0, marketshare: 0, hsse: 0 });
          setlubes({ sales: 0, revenue: 0, marketshare: 0, hsse: 0 });
          setoverall({ sales: 0, revenue: 0, marketshare: 0, hsse: 0 });
        }
      })
      .catch((error) => console.debug(error));

    fetchRetailHsse(year)
      .then((response) => {
        if (isEmpty(response.data)) {
          setLastUpdated([]);
        } else {
          setLastUpdated(response.data[response.data.length - 1]);
        }
      })
      .catch((error) => console.debug(error));
  }, [year]);

  // if (!localStorage.getItem("bpclAccessToken")) {
  //   return <Redirect to="/login" />;
  // }

  function activateTab(tabId) {
    // console.log(tabId);
    setActiveTab(tabId);
  }

  return (
    <div className={`${styles.BuContainer}`}>
      <Dropdown className={`${styles.gheaderDropdownYear}`}>
        <Dropdown.Toggle
          variant="none"
          id="dropdown-basic"
          className={`d-flex align-items-center ${styles.gdropdownButton}`}
        >
          <span className={`${styles.gdropdownName}`}>
            {year} - {Number(year.slice(2)) + 1}
          </span>
          <div
            className={`d-flex align-items-center justify-content-center ${styles.gdropdownIconContainer}`}
          >
            <img src={dropdownArrows} alt="All region dropdown"></img>
          </div>
        </Dropdown.Toggle>
        <Dropdown.Menu className={`${styles.gdropdownMenuContainer}`}>
          {/* <Dropdown.Item className={`${styles.gmenuItem}`} onClick={() => setyear(yearPrev)}>{yearPrev} - {yearCurrent.slice(2)}</Dropdown.Item> */}
          <Dropdown.Item
            className={`${styles.gmenuItem}`}
            onClick={() => setyear(yearCurrent)}
          >
            {yearCurrent} - {yearNext.slice(2)}
          </Dropdown.Item>
          <Dropdown.Item
            className={`${styles.gmenuItem}`}
            onClick={() => setyear(yearNext)}
          >
            {yearNext} - {Number(yearNext.slice(2)) + 1}
          </Dropdown.Item>
          {/* <Dropdown.Item className={`${styles.gmenuItem}`} onClick={() => setyear('2022')}>2022</Dropdown.Item>
                            <Dropdown.Item className={`${styles.gmenuItem}`} onClick={() => setyear('2023')}>2023</Dropdown.Item> */}
        </Dropdown.Menu>
      </Dropdown>
      <p className={`${styles.lastUpdated}`}>
        {/* Last Updated on {new Date(lastUpdated.date).toDateString()} */}
      </p>
      <div
        className={`d-flex align-items-center justify-content-center ${styles.BuHeading}`}
      >
        <a href="/">Business Units</a>
      </div>
      <Tabs
        defaultTab="retail_tab"
        className={`d-flex ${styles.BuContent}`}
        onChange={(tabId) => activateTab(tabId)}
      >
        <TabList className={`${styles.BuSectionsContainer}`}>
          <div
            className={`d-flex align-items-center justify-content-around p-2 ${styles.tabLabelsContainer}`}
          >
            <div className="d-flex align-items-center">
              <span className={`mr-2 ${styles.poorDot}`}></span>Sales
            </div>
            {/* <div className="d-flex align-items-center">
              <span className={`mr-2 ${styles.fairDot}`}></span>HSSE
            </div> */}
            <div className="d-flex align-items-center">
              <span className={`mr-2 ${styles.goodDot}`}></span>Revenue
            </div>
            <div className="d-flex align-items-center">
              <span className={`mr-2 ${styles.excellentDot}`}></span>Market
              Share
            </div>
          </div>
          <Tab
            tabFor="overal_tab"
            className={`${
              activeTab === "overal_tab" && styles.tabOverallActive
            } ${styles.tabOverall}`}
          >
            <div
              className={`d-flex align-items-center ${
                activeTab === "overal_tab" && styles.overallHeaderActive
              } ${styles.overallHeader}`}
            >
              OVERALL
            </div>
            <div
              className={`${styles.overallContent} ${
                activeTab === "overal_tab" && styles.overallContentActive
              } `}
            >
              <div className={`${styles.tabKpi}`}>Sales</div>
              <div
                className={`d-flex align-items-center ${styles.barAndValue}`}
              >
                <div className={`${styles.progressBarRed}`}>
                  <span style={{ width: "100%" }}></span>
                </div>
                <span className={"ml-4"}>
                  {overall.sales &&
                    numberFormatEnIn(Number(overall.sales).toFixed(2))}{" "}
                  TKL
                </span>
              </div>

              <div className={`${styles.tabKpi}`}>Revenue</div>
              <div
                className={`d-flex align-items-center ${styles.barAndValue}`}
              >
                <div className={`${styles.progressBarYellow}`}>
                  <span style={{ width: "100%" }}></span>
                </div>
                <span className={`ml-4 ${styles.tabKpiValue}`}>
                  &#8377;{" "}
                  {overall.revenue &&
                    numberFormatEnIn(Number(overall.revenue).toFixed(2))}{" "}
                  Cr
                </span>
              </div>

              <div className={`${styles.tabKpi}`}>Market Share</div>
              <div
                className={`d-flex align-items-center ${styles.barAndValue}`}
              >
                <div className={`${styles.progressBarGreen}`}>
                  <span style={{ width: "100%" }}></span>
                </div>
                <span className={`ml-4 ${styles.tabKpiValue}`}>
                  {overall.marketshare &&
                    numberFormatEnIn(Number(overall.marketshare).toFixed(2))}
                  %
                </span>
              </div>

              {/* <div className={`${styles.tabKpi}`}>HSSE (Incidents)</div>
              <div
                className={`d-flex align-items-center ${styles.barAndValue}`}
              >
                <div className={`${styles.progressBarOrange}`}>
                  <span style={{ width: "100%" }}></span>
                </div>
                <span className={`ml-4 ${styles.tabKpiValue}`}>
                  {overall.hsse && overall.hsse}
                </span>
              </div> */}
            </div>
          </Tab>
          <Tab
            tabFor="retail_tab"
            className={`${
              activeTab === "retail_tab" && styles.tabOverallActive
            } ${styles.tabOverall}`}
          >
            <div
              className={`d-flex align-items-center ${
                activeTab === "retail_tab" && styles.overallHeaderActive
              } ${styles.overallHeader}`}
            >
              RETAIL
            </div>
            <div
              className={`${styles.overallContent} ${
                activeTab === "retail_tab" && styles.overallContentActive
              } `}
            >
              <div className={`${styles.tabKpi}`}>Sales</div>
              <div
                className={`d-flex align-items-center ${styles.barAndValue}`}
              >
                <div className={`${styles.progressBarRed}`}>
                  <span style={{ width: "100%" }}></span>
                </div>
                <span className={"ml-4"}>
                  {retail.sales &&
                    numberFormatEnIn(Number(retail.sales).toFixed(2))}{" "}
                  TKL
                </span>
              </div>

              <div className={`${styles.tabKpi}`}>Revenue</div>
              <div
                className={`d-flex align-items-center ${styles.barAndValue}`}
              >
                <div className={`${styles.progressBarYellow}`}>
                  <span style={{ width: "100%" }}></span>
                </div>
                <span className={`ml-4 ${styles.tabKpiValue}`}>
                  &#8377;{" "}
                  {retail.revenue &&
                    numberFormatEnIn(Number(retail.revenue).toFixed(2))}{" "}
                  Cr
                </span>
              </div>

              <div className={`${styles.tabKpi}`}>Market Share</div>
              <div
                className={`d-flex align-items-center ${styles.barAndValue}`}
              >
                <div className={`${styles.progressBarGreen}`}>
                  <span style={{ width: "100%" }}></span>
                </div>
                <span className={`ml-4 ${styles.tabKpiValue}`}>
                  {retail.marketshare &&
                    numberFormatEnIn(Number(retail.marketshare).toFixed(2))}
                  %
                </span>
              </div>
{/* 
              <div className={`${styles.tabKpi}`}>HSSE (Incidents)</div>
              <div
                className={`d-flex align-items-center ${styles.barAndValue}`}
              >
                <div className={`${styles.progressBarOrange}`}>
                  <span style={{ width: "100%" }}></span>
                </div>
                <span className={`ml-4 ${styles.tabKpiValue}`}>
                  {retail.hsse && retail.hsse}
                </span>
              </div> */}
            </div>
          </Tab>
          <Tab
            tabFor="lpg_tab"
            className={`${activeTab === "lpg_tab" && styles.tabOverallActive} ${
              styles.tabOverall
            }`}
          >
            <div
              className={`d-flex align-items-center ${
                activeTab === "lpg_tab" && styles.overallHeaderActive
              } ${styles.overallHeader}`}
            >
              LPG
            </div>
            <div
              className={`${styles.overallContent} ${
                activeTab === "lpg_tab" && styles.overallContentActive
              } `}
            >
              <div className={`${styles.tabKpi}`}>Sales</div>
              <div
                className={`d-flex align-items-center ${styles.barAndValue}`}
              >
                <div className={`${styles.progressBarRed}`}>
                  <span style={{ width: "100%" }}></span>
                </div>
                <span className={"ml-4"}>
                  {lpg.sales && numberFormatEnIn(Number(lpg.sales).toFixed(2))}{" "}
                  TMT
                </span>
              </div>

              <div className={`${styles.tabKpi}`}>Revenue</div>
              <div
                className={`d-flex align-items-center ${styles.barAndValue}`}
              >
                <div className={`${styles.progressBarYellow}`}>
                  <span style={{ width: "100%" }}></span>
                </div>
                <span className={`ml-4 ${styles.tabKpiValue}`}>
                  &#8377;{" "}
                  {lpg.revenue &&
                    numberFormatEnIn(Number(lpg.revenue).toFixed(2))}{" "}
                  Cr
                </span>
              </div>

              <div className={`${styles.tabKpi}`}>Market Share</div>
              <div
                className={`d-flex align-items-center ${styles.barAndValue}`}
              >
                <div className={`${styles.progressBarGreen}`}>
                  <span style={{ width: "100%" }}></span>
                </div>
                <span className={`ml-4 ${styles.tabKpiValue}`}>
                  {lpg.marketshare &&
                    numberFormatEnIn(Number(lpg.marketshare).toFixed(2))}
                  %
                </span>
              </div>

              {/* <div className={`${styles.tabKpi}`}>HSSE (Incidents)</div>
              <div
                className={`d-flex align-items-center ${styles.barAndValue}`}
              >
                <div className={`${styles.progressBarOrange}`}>
                  <span style={{ width: "100%" }}></span>
                </div>
                <span className={`ml-4 ${styles.tabKpiValue}`}>
                  {lpg.hsse && lpg.hsse}
                </span>
              </div> */}
            </div>
          </Tab>
          <Tab
            tabFor="atf_tab"
            className={`${activeTab === "atf_tab" && styles.tabOverallActive} ${
              styles.tabOverall
            }`}
          >
            <div
              className={`d-flex align-items-center ${
                activeTab === "atf_tab" && styles.overallHeaderActive
              }  ${styles.overallHeader} `}
            >
              Aviation
            </div>
            <div
              className={`${styles.overallContent} ${
                activeTab === "atf_tab" && styles.overallContentActive
              }`}
            >
              <div className={`${styles.tabKpi}`}>Sales</div>
              <div
                className={`d-flex align-items-center ${styles.barAndValue}`}
              >
                <div className={`${styles.progressBarRed}`}>
                  <span style={{ width: "100%" }}></span>
                </div>
                <span className={"ml-4"}>
                  {atf.sales && numberFormatEnIn(Number(atf.sales).toFixed(2))}{" "}
                  TKL
                </span>
              </div>

              <div className={`${styles.tabKpi}`}>Revenue</div>
              <div
                className={`d-flex align-items-center ${styles.barAndValue}`}
              >
                <div className={`${styles.progressBarYellow}`}>
                  <span style={{ width: "100%" }}></span>
                </div>
                <span className={`ml-4 ${styles.tabKpiValue}`}>
                  &#8377;{" "}
                  {atf.revenue &&
                    numberFormatEnIn(Number(atf.revenue).toFixed(2))}{" "}
                  Cr
                </span>
              </div>

              <div className={`${styles.tabKpi}`}>Market Share</div>
              <div
                className={`d-flex align-items-center ${styles.barAndValue}`}
              >
                <div className={`${styles.progressBarGreen}`}>
                  <span style={{ width: "100%" }}></span>
                </div>
                <span className={`ml-4 ${styles.tabKpiValue}`}>
                  {atf.marketshare &&
                    numberFormatEnIn(Number(atf.marketshare).toFixed(2))}
                  %
                </span>
              </div>

              {/* <div className={`${styles.tabKpi}`}>HSSE (Incidents)</div>
              <div
                className={`d-flex align-items-center ${styles.barAndValue}`}
              >
                <div className={`${styles.progressBarOrange}`}>
                  <span style={{ width: "100%" }}></span>
                </div>
                <span className={`ml-4 ${styles.tabKpiValue}`}>
                  {atf.hsse && atf.hsse}
                </span>
              </div> */}
            </div>
          </Tab>
          <Tab
            tabFor="inc_tab"
            className={`${activeTab === "inc_tab" && styles.tabOverallActive} ${
              styles.tabOverall
            }`}
          >
            <div
              className={`d-flex align-items-center ${
                activeTab === "inc_tab" && styles.overallHeaderActive
              } ${styles.overallHeader}`}
            >
              I&C
            </div>
            <div
              className={`${styles.overallContent} ${
                activeTab === "inc_tab" && styles.overallContentActive
              }`}
            >
              <div className={`${styles.tabKpi}`}>Sales</div>
              <div
                className={`d-flex align-items-center ${styles.barAndValue}`}
              >
                <div className={`${styles.progressBarRed}`}>
                  <span style={{ width: "100%" }}></span>
                </div>
                <span className={"ml-4"}>
                  {inc.sales && numberFormatEnIn(Number(inc.sales).toFixed(2))}{" "}
                  TMT
                </span>
              </div>

              <div className={`${styles.tabKpi}`}>Revenue</div>
              <div
                className={`d-flex align-items-center ${styles.barAndValue}`}
              >
                <div className={`${styles.progressBarYellow}`}>
                  <span style={{ width: "100%" }}></span>
                </div>
                <span className={`ml-4 ${styles.tabKpiValue}`}>
                  &#8377;{" "}
                  {inc.revenue &&
                    numberFormatEnIn(Number(inc.revenue).toFixed(2))}{" "}
                  Cr
                </span>
              </div>

              <div className={`${styles.tabKpi}`}>Market Share</div>
              <div
                className={`d-flex align-items-center ${styles.barAndValue}`}
              >
                <div className={`${styles.progressBarGreen}`}>
                  <span style={{ width: "100%" }}></span>
                </div>
                <span className={`ml-4 ${styles.tabKpiValue}`}>
                  {inc.marketshare &&
                    numberFormatEnIn(Number(inc.marketshare).toFixed(2))}
                  %
                </span>
              </div>

              {/* <div className={`${styles.tabKpi}`}>HSSE (Incidents)</div>
              <div
                className={`d-flex align-items-center ${styles.barAndValue}`}
              >
                <div className={`${styles.progressBarOrange}`}>
                  <span style={{ width: "100%" }}></span>
                </div>
                <span className={`ml-4 ${styles.tabKpiValue}`}>
                  {inc.hsse && inc.hsse}
                </span>
              </div> */}
            </div>
          </Tab>
          <Tab
            tabFor="lubes_tab"
            className={`${
              activeTab === "lubes_tab" && styles.tabOverallActive
            } ${styles.tabOverall}`}
          >
            <div
              className={`d-flex align-items-center ${
                activeTab === "lubes_tab" && styles.overallHeaderActive
              } ${styles.overallHeader}`}
            >
              LUBES
            </div>
            <div
              className={`${styles.overallContent} ${
                activeTab === "lubes_tab" && styles.overallContentActive
              } `}
            >
              <div className={`${styles.tabKpi}`}>Sales</div>
              <div
                className={`d-flex align-items-center ${styles.barAndValue}`}
              >
                <div className={`${styles.progressBarRed}`}>
                  <span style={{ width: "100%" }}></span>
                </div>
                <span className={"ml-4"}>
                  {lubes.sales &&
                    numberFormatEnIn(Number(lubes.sales).toFixed(2))}{" "}
                  TKL
                </span>
              </div>

              <div className={`${styles.tabKpi}`}>Revenue</div>
              <div
                className={`d-flex align-items-center ${styles.barAndValue}`}
              >
                <div className={`${styles.progressBarYellow}`}>
                  <span style={{ width: "100%" }}></span>
                </div>
                <span className={`ml-4 ${styles.tabKpiValue}`}>
                  &#8377;{" "}
                  {lubes.revenue &&
                    numberFormatEnIn(Number(lubes.revenue).toFixed(2))}{" "}
                  Cr
                </span>
              </div>

              <div className={`${styles.tabKpi}`}>Market Share</div>
              <div
                className={`d-flex align-items-center ${styles.barAndValue}`}
              >
                <div className={`${styles.progressBarGreen}`}>
                  <span style={{ width: "100%" }}></span>
                </div>
                <span className={`ml-4 ${styles.tabKpiValue}`}>
                  {lubes.marketshare &&
                    numberFormatEnIn(Number(lubes.marketshare).toFixed(2))}
                  %
                </span>
              </div>

              {/* <div className={`${styles.tabKpi}`}>HSSE (Incidents)</div>
              <div
                className={`d-flex align-items-center ${styles.barAndValue}`}
              >
                <div className={`${styles.progressBarOrange}`}>
                  <span style={{ width: "100%" }}></span>
                </div>
                <span className={`ml-4 ${styles.tabKpiValue}`}>
                  {lubes.hsse && lubes.hsse}
                </span>
              </div> */}
            </div>
          </Tab>
          {/* <Tab
            tabFor="gasBu"
            className={`${
              activeTab === "gasBu" && styles.tabOverallActive
            } ${styles.tabOverall}`}
          >
            <div
              className={`d-flex align-items-center ${
                activeTab === "gasBu" && styles.overallHeaderActive
              } ${styles.overallHeader}`}
            >
              GAS              
            </div>
          </Tab> */}
          {/* Checking */}
          <Tab
            tabFor="itEcosystem"
            className={`${
              activeTab === "itEcosystem" && styles.tabOverallActive
            } ${styles.tabOverall}`}
          >
            <div
              className={`d-flex align-items-center ${
                activeTab === "itEcosystem" && styles.overallHeaderActive
              } ${styles.overallHeader}`}
            >
              SYSTEM ARCHITECTURE
            </div>
          </Tab>
          {/* <Tab
            tabFor="project"
            className={`${
              activeTab === "project" && styles.tabOverallActive
            } ${styles.tabOverall}`}
          >
            <div
              className={`d-flex align-items-center ${
                activeTab === "project" && styles.overallHeaderActive
              } ${styles.overallHeader}`}
            >
              PROJECTS
            </div>
          </Tab> */}
        </TabList>
        {activeTab === "retail_tab" && (
          <TabPanel tabId="retail_tab">
            <div className={"d-flex align-items-center"}>
              <BuRetailMapContainer
                handleRegionDropdown={handleRegionDropdown}
                mapOf={mapOf}
                mapCenter={mapCenter}
                mapZoom={mapZoom}
                activeTab={activeTab}
                year={year}
              ></BuRetailMapContainer>
              <BuGraphs
                handleRegionDropdown={handleRegionDropdown}
                mapOf={mapOf}
                activeTab={activeTab}
                year={year}
              ></BuGraphs>
            </div>
          </TabPanel>
        )}
        {activeTab === "lpg_tab" && (
          <TabPanel tabId="lpg_tab">
            <div className={"d-flex"}>
              <BuLpgMapContainer
                handleRegionDropdown={handleRegionDropdown}
                mapOf={mapOf}
                mapCenter={mapCenter}
                mapZoom={mapZoom}
                activeTab={activeTab}
                year={year}
              ></BuLpgMapContainer>
              <BuLpgGraphs
                handleRegionDropdown={handleRegionDropdown}
                mapOf={mapOf}
                activeTab={activeTab}
                year={year}
              ></BuLpgGraphs>
            </div>
          </TabPanel>
        )}
        {activeTab === "atf_tab" && (
          <TabPanel tabId="atf_tab">
            <div className={"d-flex align-items-center"}>
              <BuAtfMapContainer
                handleRegionDropdown={handleRegionDropdown}
                mapOf={mapOf}
                mapCenter={mapCenter}
                mapZoom={mapZoom}
                activeTab={activeTab}
                year={year}
              ></BuAtfMapContainer>
              <BuAtfGraphs
                handleRegionDropdown={handleRegionDropdown}
                mapOf={mapOf}
                activeTab={activeTab}
                year={year}
              ></BuAtfGraphs>
            </div>
          </TabPanel>
        )}
        {activeTab === "inc_tab" && (
          <TabPanel tabId="inc_tab">
            <div className={"d-flex align-items-center"}>
              <BuIncMapContainer
                handleRegionDropdown={handleRegionDropdown}
                mapOf={mapOf}
                mapCenter={mapCenter}
                mapZoom={mapZoom}
                activeTab={activeTab}
                year={year}
              ></BuIncMapContainer>
              <BuIncGraphs
                handleRegionDropdown={handleRegionDropdown}
                mapOf={mapOf}
                activeTab={activeTab}
                year={year}
              ></BuIncGraphs>
            </div>
          </TabPanel>
        )}
        {activeTab === "lubes_tab" && (
          <TabPanel tabId="lubes_tab">
            <div className={"d-flex align-items-center"}>
              <BuLubesMapContainer
                handleRegionDropdown={handleRegionDropdown}
                mapOf={mapOf}
                mapCenter={mapCenter}
                mapZoom={mapZoom}
                activeTab={activeTab}
                year={year}
              ></BuLubesMapContainer>
              <BuLubesGraphs
                handleRegionDropdown={handleRegionDropdown}
                mapOf={mapOf}
                activeTab={activeTab}
                year={year}
              ></BuLubesGraphs>
            </div>
          </TabPanel>
        )}
        {activeTab === "itEcosystem" && (
          <TabPanel tabId="itEcosystem">
            <div className={`${styles.refinaryGraphs}`}>
              <video
                style={{
                  // position: 'relative',
                  // width: '100%',
                  // height: '2000px'
                  position: "initial",
                  width: "4760px",
                  height: "1955px",
                  objectFit: "fill",
                }}
                autoPlay
                loop
                src={itEcosystem}
              ></video>
              {/* <div className={`${styles.vts}`}>
                 <div className={`${styles.circlegreen}`}></div>
              </div>
              <div className={`${styles.emLock}`}> */}
              {/* <div className={`${styles.circlered}`}></div> */}
              {/* <div className={`${styles.circlegreen}`}></div>
              </div>
              <div className={`${styles.cwps}`}>
              <div className={`${styles.circlegreen}`}></div>
              </div> */}
              {/* <div className={`${styles.analytics}`}>
              <div className={`${styles.circlegreen}`}></div>
              </div>
              <div className={`${styles.hos}`}>
              <div className={`${styles.circlegreen}`}></div>
              </div>
              <div className={`${styles.cep}`}>
              <div className={`${styles.circlegreen}`}></div>
              </div>
              <div className={`${styles.fWall1}`}>
              <div className={`${styles.circlegreen}`}></div>
              </div>
              <div className={`${styles.fWall2}`}>
              <div className={`${styles.circlegreen}`}></div>
              </div>
              <div className={`${styles.fWall3}`}>
              <div className={`${styles.circlegreen}`}></div>
              </div>
              <div className={`${styles.fWall4}`}>
              <div className={`${styles.circlegreen}`}></div>
              </div> */}
            </div>
          </TabPanel>
        )}
        {activeTab === "project" && (
          <TabPanel tabId="project">
            <div className={"d-flex align-items-center"}>
              <BuGraphsProject
                handleRegionDropdown={handleRegionDropdown}
                mapOf={mapOf}
                activeTab={activeTab}
                year={year}
              ></BuGraphsProject>
            </div>
          </TabPanel>
        )}
        {/* {activeTab === "gasBu" && (
          <TabPanel tabId="gasBu">
            <iframe src="https://gas.hellobpcl.in/login" width={4760} height={1950}></iframe>
          </TabPanel>
        )} */}
      </Tabs>
    </div>
  );
};
export default BuLanding;
