import axios from 'axios'
import { withAuthorizationHeaders } from '../components/Authentication/services/authHeaders'
import { ITRM_KPI_URL, ORG_KPI_URL, PLANNING_KPI_URL } from '../environment'

export const fetchItrmRegionData = async (refinery, dataType, year) => {
    let url = `${ITRM_KPI_URL}/crude_procurement/region?refinery=${refinery}&data_type=${dataType}&year=${year}`
    const res = await axios.get(url, { withCredentials: true })
    return res.data
}

export const fetchItrmCountryData = async (region, refinery, year) => {
    let encodedRegion = region.replace(/ /g, "+");
    let url = `${ITRM_KPI_URL}/crude_procurement/country?refinery=${refinery}&data_type=ytm&region=${encodedRegion}&year=${year}`
    const res = await axios.get(url, { withCredentials: true })
    return res.data
}

export const fetchItrmlandedCost = async (refinery, year) => {
    let region = "All regions";
    // Encode the URL parameter to ensure safe transmission
    let encodedRegion = region.replace(/ /g, "+");;
    let url = `${ITRM_KPI_URL}/landed_cost?refinery=${refinery}&data_type=ytm&region=${encodedRegion}&year=${year}`
    const res = await axios.get(url, { withCredentials: true })
    return res.data
}

export const fetchItrmTableDataHs = async (refinery, region, year) => {
    let encodedRegion = region.replace(/ /g, "+");
    let url = `${ITRM_KPI_URL}/crude_procurement_detail/?refinery=${refinery}&data_type=ytm&oil_type=hs&region=${encodedRegion}&year=${year}`
    const res = await axios.get(url, { withCredentials: true })
    return res.data
}

export const fetchItrmTableDataLs = async (refinery, region, year) => {
    let encodedRegion = region.replace(/ /g, "+");
    let url = `${ITRM_KPI_URL}/crude_procurement_detail/?refinery=${refinery}&data_type=ytm&oil_type=ls&region=${encodedRegion}&year=${year}`
    const res = await axios.get(url, { withCredentials: true })
    return res.data
}

export const fetchItrmCountryChart = async (region) => {
    let encodedRegion = region.replace(/ /g, "+");
    let url = `${ITRM_KPI_URL}/crude_procurement/country?refinery=mumbai&data_type=ytm&region=${encodedRegion}`
    const res = await axios.get(url, { withCredentials: true })
    return res.data
}

export const getTableDataFuture = async () => {
    let url = `${ITRM_KPI_URL}/tablefuture`
    const res = await axios.get(url, { withCredentials: true })
    return res.data
}

export const getProductCrack = async () => {
    let url = `${ITRM_KPI_URL}/crack`
    const res = await axios.get(url, { withCredentials: true })
    return res.data
}
export const getForwardCurveData = async () => {
    let url = `${ITRM_KPI_URL}/forward_curve`
    const res = await axios.get(url, { withCredentials: true })
    return res.data
}
export const fetchItrmLanding = async () => {
    const res = await axios.get(
        `${ORG_KPI_URL}/orgyr`,
        { withCredentials: true }
    )
    return res.data
}

export const fetchupdatedStatus = async (year) => {
    let url = `${PLANNING_KPI_URL}/getUpdatedStatus?year=${year}&bu=ITRM`
    const res = await axios.get(url, { withCredentials: true })
    return res.data
}
