import React, { useContext } from "react";
import styles from "./HomeLanding.module.scss";
import bularge from "../../assets/images/homepage/bu/bularge.png";
import orglarge from "../../assets/images/homepage/organization/orglarge.png";
import moularge from "../../assets/images/homepage/mou/moularge.png";
import itrmlarge from "../../assets/images/homepage/itrm/itrmlarge.png";
import refinerylarge from "../../assets/images/homepage/refinery/refinerylarge.png";
import { logoutUser } from "../../services/auth.js"
import { Link, Redirect, useHistory } from "react-router-dom";
import ApplicationContext from "../../ApplicationContext";

function HomeLanding() {
  const context = useContext(ApplicationContext);
  const pagelinks = {
    itrm: "/itrm",
    bu: "/bu",
    org: "/org",
    refinery: "/refinery",
    mou: "/mou",
  };

  let history = useHistory();
  const logOut = () => {
    // context.setLoggedInUser(null);
    setTimeout(() => {
      history.push("/login");
      // window.location.href = '/login'
    }, 1000);
    logoutUser()
    .then((data) => {
      context.setLoggedInUser(null);
      // if (data.success === 1) {
      //   context.setLoggedInUser(data);
      //   setTimeout(() => {
      //     history.push("/");
      //   }, 500);
      // } else {
      //   context.setLoggedInUser(null);
      //   setSignintext("SIGN IN");
      //   setshowPopup(1);
      //   setError(data.message);
      // }
    })
    .catch((error) => {
      console.debug(error);
    });
  };
  const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
  if (loggedInUser != null) {
    const uEmail = loggedInUser.email;
    if (
      uEmail != "test@bharatpetroleum.in" &&
      uEmail != "rajeshkv@bharatpetroleum.in" &&
      uEmail != "khannm@bharatpetroleum.in" &&
      uEmail != "ashraful.hoda@bharatpetroleum.in" &&
      uEmail != "k.kisan.sawalkar@bharatpetroleum.in" &&
      uEmail != "varad.birwatkar@bharatpetroleum.in"
    ) {
    setTimeout(logOut, 1800000);
    }
  }
  if (!context.loggedInUser) {
    return <Redirect to="/login" />;
  }
  return (
    <div className={`${styles.HomeSection}`}>
      <div className="row">
        <div className="col-md-11">
          <h4 className={`${styles.title}`}>BHARAT PETROLEUM </h4>
        </div>
        <div className="col-md-1">
          <button className="btn btn-primary btn-block" onClick={logOut}>
            Logout
          </button>
        </div>
      </div>

      <div className={`${styles.menuSection}`}>
        <div className={`d-flex flex-row ${styles.justifycontent}`}>
          <Link
            to={`${pagelinks.itrm}`}
            className={`d-flex ${styles.Menubox1}`}
          >
            <div className={`${styles.MenuContentbox}`}>
              <img src={itrmlarge} alt="ITRM" />
              <h3>ITRM</h3>
              <p>Proceed to ITRM</p>
            </div>
          </Link>
          <Link
            to={`${pagelinks.refinery}`}
            className={`d-flex ${styles.Menubox2}`}
          >
            <div className={`${styles.MenuContentbox}`}>
              <img src={refinerylarge} alt="Refinery" />
              <h3>REFINERY</h3>
              <p>Proceed to Refinery</p>
            </div>
          </Link>
          <Link to={`${pagelinks.bu}`} className={`d-flex ${styles.Menubox3}`}>
            <div className={`${styles.MenuContentbox}`}>
              <img src={bularge} alt="BU" />
              <h3>BUSINESS UNITS</h3>
              <p>Proceed to Business Units</p>
            </div>
          </Link>
        </div>
        <div className={`d-flex flex-row ${styles.justifycontent}`}>
          <Link to={`${pagelinks.org}`} className={`d-flex ${styles.Menubox2}`}>
            <div className={`${styles.MenuContentbox}`}>
              <img src={orglarge} alt="ORG" />
              <h3>ORGANIZATION KPIs</h3>
              <p>Proceed to ORG KPIs</p>
            </div>
          </Link>
          {/* <Link to={`${pagelinks.mou}`} className={`d-flex ${styles.Menubox3}`}>
                        <div className={`${styles.MenuContentbox}`}>
                            <img src={moularge} alt="MOU" />
                            <h3>MOU</h3>
                            <p>Proceed to MOU</p>
                        </div>
                    </Link> */}
          <div className={`${styles.Menubox4}`}></div>
        </div>
      </div>
    </div>
  );
}
export default HomeLanding;
