import React, { useState } from 'react'
import styles from './OrgFinance.module.scss'
import FinanceGrowth from './FinanceGrowth/FinanceGrowth'
import FinanceMarket from './FinanceMarket/FinanceMarket'
import FinanceTreasurey from './FinanceTreasurey/FinanceTreasurey'
import ProfitLoss from './ProfitLoss/ProfitLoss'
import TotalCapex from './TotalCapex/TotalCapex'
import BalanceSheets from './BalanceSheets/BalanceSheets'
import FinancialRation from './FinancialRatio/FinancialRatio'
const OrgFinance = (props) => {
    const [ofdropdown] = useState('YTM')
    const [menu, setMenu] = useState('Finance')

    return (
        <div className={`${styles.financeContainer}`}>
           <div className={`d-flex align-items-center justify-content-center ${styles.financeHeader1}`}>
            {/* <Dropdown className={`${styles.gheaderDropdownYear}`}>
                    <Dropdown.Toggle variant="none" id="dropdown-basic" className={`d-flex align-items-center ${styles.gdropdownButton}`}>
                        <span className={`${styles.gdropdownName}`}>{hr}</span>
                        <div className={`d-flex align-items-center justify-content-center ${styles.gdropdownIconContainer}`}>
                            <img src={dropdownArrows} alt='All region dropdown'></img>
                        </div>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className={`${styles.gdropdownMenuContainer}`}>
                        <Dropdown.Item className={`${styles.gmenuItem}`} onClick={() => setHR('HR')}>HR</Dropdown.Item>
                        <Dropdown.Item className={`${styles.gmenuItem}`} onClick={() => setHR('Projects')}>PROJECTS</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown> */}
                <div className={`${styles.gheaderDropdownYear}`}>
                    {/* <div variant="none" id="dropdown-basic" className={`d-flex align-items-center ${styles.gdropdownButton}`}>
                        <span className={`${styles.gdropdownName}`}>{hr}</span>
                        <div className={`d-flex align-items-center justify-content-center ${styles.gdropdownIconContainer}`}>
                            <img src={dropdownArrows} alt='All region dropdown'></img>
                        </div>
                    </div> */}
                    <div className={`${styles.gdropdownMenuContainer}`}>
                        <button className={`${styles.gmenuItem}`} onClick={() => setMenu('Finance')}>FINANCE</button>
                        <button className={`${styles.gmenuItem}`} onClick={() => setMenu('Projects')}>GAS</button>
                    </div>
                </div>
                {/* <Dropdown className={`${styles.hrheaderDropdown}`}>
                    <Dropdown.Toggle variant="none" id="dropdown-basic" className={`d-flex align-items-center ${styles.hrdropdownButton}`}>
                        <span className={`${styles.hrdropdownName}`}>View as {ohrdropdown}</span>
                        <div className={`d-flex align-items-center justify-content-center ${styles.hrdropdownIconContainer}`}>
                            <img src={dropdownArrows} alt='All region dropdown'></img>
                        </div>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className={`${styles.hrdropdownMenuContainer}`}>
                        <Dropdown.Item className={`${styles.hrmenuItem}`} onClick={() => setohrdropdown('YTM')} >YTM</Dropdown.Item>
                        <Dropdown.Item className={`${styles.hrmenuItem}`} onClick={() => setohrdropdown('MTD')}>MTD</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown> */}
            </div>
            {(menu === 'Finance') ?
                <div className={`d-flex flex-column ${styles.financeContentContainer}`}>
                    <div className={`d-flex ${styles.growthAndMarketContainer}`}>
                        <FinanceGrowth dropdown={ofdropdown} year={props.year}></FinanceGrowth>
                        <FinanceMarket dropdown={ofdropdown} year={props.year}></FinanceMarket>
                    </div>
                    <div className={`d-flex ${styles.restOfFinanceContainer}`}>
                        <div className={`d-flex flex-column justify-content-between ${styles.column1}`}>
                            <ProfitLoss dropdown={ofdropdown} year={props.year}></ProfitLoss>
                            <TotalCapex dropdown={ofdropdown} year={props.year}></TotalCapex>
                        </div>
                        <FinanceTreasurey dropdown={ofdropdown} year={props.year}></FinanceTreasurey>
                        <div className={`d-flex flex-column justify-content-between ${styles.column3}`}>
                            <FinancialRation dropdown={ofdropdown} year={props.year}></FinancialRation>
                            <BalanceSheets dropdown={ofdropdown} year={props.year}></BalanceSheets>
                        </div>
                    </div>
                </div>
                :
                <iframe src="https://gas.hellobpcl.in/login" sandbox width={3120} height={1900}></iframe>
            }
        </div>
    )
}
export default OrgFinance