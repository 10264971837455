import React, { useState, useEffect } from 'react'
import styles from './RefinaryEnergyIndex.module.scss'
import PolarAreaGraph from '../../../charts/PolarAreaChart'
import { Spinner } from 'react-bootstrap'
import { fetchEnergyIntensityIndex, fetchupdatedStatus } from '../../../services/Refinery'
import { NoDataFound } from '../../DataNotFound/nodatafound'
import { isEmpty } from 'lodash'
import greenTick from '../../../assets/images/greenTick.png'
import "../../../Common.scss"

const RefinaryEnergyIndex = (props) => {
    const [setLoading] = useState(true)
    const [graphData, setGraphData] = useState([])
    const [isNoDataAvailable, setIsNoDataAvailable] = useState(false)
    const [updatedStatus, setUpdatedStatus] = useState('')

    // console.debug("**********");
    // console.debug(props.location);

    useEffect(() => {
        const staticLocation = props.location === "mumbai" ? "Mumbai" : props.location === "kochi" ? "Kochi" : "Bina"
        fetchupdatedStatus(props.year)
                .then(response => {
                    if (!isEmpty(response)) {
                        response.filter((res) => res.entity === staticLocation && res.kpi_name === "Energy Intensity Index" ? setUpdatedStatus(res.status) : false);
                    } else {
                        setUpdatedStatus("Not Found")
                    }
                })
                .catch(error => console.debug(error))
        fetchEnergyIntensityIndex(props.year, props.location )
            .then((response) => {
                if (isEmpty(response.data)) {
                    setIsNoDataAvailable(true)
                } else {
                    setGraphData(response.data)
                    // setLoading(false)
                    setIsNoDataAvailable(false)

                }
            })
            .catch((error) => console.debug(error))
    }, [props.location, props.year])

    return (
        <div
            className={`d-flex flex-column justify-content-between ${styles.EnergyIndexContainer}`}
        >
            <div className={`d-flex align-items-center ${styles.graphTitle}`}>
                <span>Energy Intensity Index - EII - Monthly</span>
                {(updatedStatus === "Updated") && <div className={`${styles.statusUpdatedImg}`}>
                    <img src={greenTick} className="greenTik" alt='Expand Icon'></img>
                </div>}
            </div>
            {isNoDataAvailable ?
                <NoDataFound />
                :
                (

                    <>
                        {
                            graphData ?
                                <PolarAreaGraph
                                    location={props.location}
                                    graphData={graphData}
                                ></PolarAreaGraph> :
                                <Spinner animation="border" role="status">
                                    <span className="sr-only">Loading...</span>
                                </Spinner>
                        }


                       
                    </>
                )}
                 <div
                            className={`d-flex align-items-center justify-content-end ${styles.refineryEnergyIndexFooter}`}
                        >
                            <div className={`${styles.footerRight}`}>
                                <span>
                                    {graphData !== undefined && graphData.length > 0 ? '' : ''}
                                </span>
                            </div>
                        </div>

        </div>
    )
}
export default RefinaryEnergyIndex
