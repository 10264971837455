import React, { useState, useEffect } from 'react'
import styles from './BuLpgMapTable.module.scss'
import { fetchLpgMaptable } from '../../../services/bu'
import { numberFormatEnIn } from '../../../utility/utility'

const BuRetailMapTable = (props) => {
    const [allData, setAllData] = useState([])
    const [salesRevMarkData, setSalesRevMarkData] = useState([])
    const [hsseData, setHsseData] = useState([])
    const [plantData, setPlantData] = useState([])
    const [supplyData, setSupplyData] = useState([])
    const [northData, setnorthData] = useState([])
    const [eastData, seteastData] = useState([])
    const [westData, setwestData] = useState([])
    const [southData, setsouthData] = useState([])

    const getData = () => {
        let url = ''
        setAllData([])
        setSalesRevMarkData([])
        setHsseData([])
        setPlantData([])
        setSupplyData([])
        setnorthData([])
        seteastData([])
        setwestData([])
        setsouthData([])
        
        let level = ''
        let appendRegion = ''
        if (props.mapOf.toLocaleLowerCase() === 'north' || props.mapOf.toLocaleLowerCase() === 'south' || props.mapOf.toLocaleLowerCase() === 'east' || props.mapOf.toLocaleLowerCase() === 'west') {
            level = 'region'
            appendRegion = `&region=${props.mapOf}`
        } else {
            level = 'region'
            appendRegion = ''
        }

        fetchLpgMaptable(props.activeKpi, level, appendRegion, props.year)
            .then((response) => {
                if (props.activeKpi === 'all') {
                    let east_array = []
                    let west_array = []
                    let south_array = []
                    let north_array = []
                    response.data.forEach(getRegion => {
                        if (getRegion.region === 'east') {
                            east_array.push(getRegion)
                            seteastData(east_array[east_array.length - 1])
                        } else if (getRegion.region === 'west') {
                            west_array.push(getRegion)
                            setwestData(west_array[west_array.length - 1])
                        } else if (getRegion.region === 'north') {
                            north_array.push(getRegion)
                            setnorthData(north_array[north_array.length - 1])
                        } else if (getRegion.region === 'south') {
                            south_array.push(getRegion)
                            setsouthData(south_array[south_array.length - 1])
                        }
                    })
                    setAllData(response.data.sort().reverse())
                }
                //         else if (
                //             props.activeKpi === 'sales' ||
                //   props.activeKpi === 'revenue' ||
                //   props.activeKpi === 'market share'
                //         ) {
                //             setSalesRevMarkData(response.data.sort().reverse())
                //         } else if (props.activeKpi === 'hsse') {
                //             setHsseData(response.data.sort().reverse())
                //         } else if (props.activeKpi === 'plant') {
                //             setPlantData(response.data.sort().reverse())
                //         } else if (props.activeKpi === 'supply') {
                //             setSupplyData(response.data.sort().reverse())
                //         }
            })
            .catch((error) => console.debug(error))
    }

    useEffect(() => {
        getData()
    }, [props.activeKpi, props.mapOf, props.year]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className={`${props.mapOf === 'all regions' ? `d-flex flex-column ${styles.BuRetailMapTableContainer}` : `d-flex flex-column ${styles.BuRetailMapTableContainerTable}`}`}>
            <div className={`d-flex flex-column ${styles.tableHeaderRowContainer}`}>
                <div className={`${styles.tableHeaderRow}`}>
                    {/* <div className={`${styles.headerCell}`}>Product Segment</div>
                        <div className={`${styles.headerCell}`}>Sales (TMT)</div>
                        <div className={`${styles.headerCell}`}>Revenue (Rs Cr)</div>
                        <div className={`${styles.headerCell}`}>BPCL Market Share (%)</div> */}
                    <div className={`${styles.headerCell}`}>Region</div>
                    <div className={`${styles.headerCell}`}>Sales (TMT)</div>
                    <div className={`${styles.headerCell}`}>Revenue (Rs Cr)</div>
                    <div className={`${styles.headerCell}`}>Contribution (%)</div>
                </div>

                {salesRevMarkData.length > 0 && (
                    <div className={`${styles.tableHeaderRow}`}>
                        <div className={`${styles.headerCell}`}>Region</div>
                        <div className={`${styles.headerCell}`}>BPCL</div>
                        <div className={`${styles.headerCell}`}>IOCL</div>
                        <div className={`${styles.headerCell}`}>HPCL</div>
                        <div className={`${styles.headerCell}`}>SHELL</div>
                        <div className={`${styles.headerCell}`}>RIL</div>
                        <div className={`${styles.headerCell}`}>NEL</div>
                    </div>
                )}

                {hsseData.length > 0 && (
                    <div className={`${styles.tableHeaderRow}`}>
                        <div className={`${styles.headerCell} `}>Plant Name</div>
                        <div className={`${styles.headerCell}`}>Lost Man Hours</div>
                        <div className={`${styles.headerCell}`}>Incidents Reported</div>
                    </div>
                )}

                {plantData.length > 0 && (
                    <div className={`${styles.tableHeaderRow}`}>
                        <div className={`${styles.headerCell}`}>Plant Name</div>
                        <div className={`${styles.headerCell}`}>Total Bottling</div>
                        <div className={`${styles.headerCell}`}>Loss/Gain (%)</div>
                        <div className={`${styles.headerCell}`}>Bulk Inventory (DOS)</div>
                        <div className={`${styles.headerCell}`}>Cylinder Inventory</div>
                    </div>
                )}
                {supplyData.length > 0 && (
                    <div className={`${styles.tableHeaderRow}`}>
                        <div className={`${styles.headerCell}`}>Planr Name</div>
                        <div className={`${styles.headerCell}`}>Incoming (MT)</div>
                        <div className={`${styles.headerCell}`}>Planned Demand (MT)</div>
                        <div className={`${styles.headerCell}`}>Actual Sales</div>
                    </div>
                )}
                <div className={` d-flex flex-column ${styles.tableDataRowContainer}`}>
                    <div className={`d-flex ${styles.tableDataRow}`}>
                        <div className={`${styles.dataCell}`}>
                            <span>North</span>
                        </div>
                        <div className={`${styles.dataCell}`}>
                            <span>{northData.sales_actual ? numberFormatEnIn(Number(northData.sales_actual).toFixed(2)) : '-'}</span>
                        </div>
                        <div className={`${styles.dataCell}`}>
                            <span>{northData.revenue_actual ? numberFormatEnIn(Number(northData.revenue_actual).toFixed(2)) : '-'}</span>
                        </div>
                        <div className={`${styles.dataCell}`}>
                            <span>{northData.bpcl_mkt_share_actual ? numberFormatEnIn(Number(northData.bpcl_mkt_share_actual).toFixed(2)) : '-'}</span>
                        </div>
                    </div>
                    <div className={`d-flex ${styles.tableDataRow}`}>
                        <div className={`${styles.dataCell}`}>
                            <span>East</span>
                        </div>
                        <div className={`${styles.dataCell}`}>
                            <span>{eastData.sales_actual && eastData.sales_actual !== null ? numberFormatEnIn(Number(eastData.sales_actual).toFixed(2)) : '-'}</span>
                        </div>
                        <div className={`${styles.dataCell}`}>
                            <span>{eastData.revenue_actual && eastData.revenue_actual !== null ? numberFormatEnIn(Number(eastData.revenue_actual).toFixed(2)) : '-'}</span>
                        </div>
                        <div className={`${styles.dataCell}`}>
                            <span>{eastData.bpcl_mkt_share_actual && eastData.bpcl_mkt_share_actual !== null ? numberFormatEnIn(Number(eastData.bpcl_mkt_share_actual).toFixed(2)) : '-'}</span>
                        </div>
                    </div>
                    <div className={`d-flex ${styles.tableDataRow}`}>
                        <div className={`${styles.dataCell}`}>
                            <span>West</span>
                        </div>
                        <div className={`${styles.dataCell}`}>
                            <span>{westData.sales_actual && westData.sales_actual !== null ? numberFormatEnIn(Number(westData.sales_actual).toFixed(2)) : '-'}</span>
                        </div>
                        <div className={`${styles.dataCell}`}>
                            <span>{westData.revenue_actual && westData.revenue_actual !== null ? numberFormatEnIn(Number(westData.revenue_actual).toFixed(2)) : '-'}</span>
                        </div>
                        <div className={`${styles.dataCell}`}>
                            <span>{westData.bpcl_mkt_share_actual && westData.bpcl_mkt_share_actual !== null ? numberFormatEnIn(Number(westData.bpcl_mkt_share_actual).toFixed(2)) : '-'}</span>
                        </div>
                    </div>
                    <div className={`d-flex ${styles.tableDataRow}`}>
                        <div className={`${styles.dataCell}`}>
                            <span>South</span>
                        </div>
                        <div className={`${styles.dataCell}`}>
                            <span>{southData.sales_actual && southData.sales_actual !== null ? numberFormatEnIn(Number(southData.sales_actual).toFixed(2)) : '-'}</span>
                        </div>
                        <div className={`${styles.dataCell}`}>
                            <span>{southData.revenue_actual && southData.revenue_actual !== null ? numberFormatEnIn(Number(southData.revenue_actual).toFixed(2)) : '-'}</span>
                        </div>
                        <div className={`${styles.dataCell}`}>
                            <span>{southData.bpcl_mkt_share_actual && southData.bpcl_mkt_share_actual !== null ? numberFormatEnIn(Number(southData.bpcl_mkt_share_actual).toFixed(2)) : '-'}</span>
                        </div>
                    </div>
                    {/* {allData &&
            allData.map((el, index) => (
                <div className={`d-flex ${styles.tableDataRow}`} key={index}>
                    <div className={`${styles.dataCell}`}>
                        <span>{el.region}</span>
                    </div>
                    <div className={`${styles.dataCell}`}>
                        <span>{el.sales_actual !== null && numberFormatEnIn(Number(el.sales_actual).toFixed(2))}</span>
                    </div>
                    <div className={`${styles.dataCell}`}>
                        <span>{el.revenue_actual !== null && numberFormatEnIn(Number(el.revenue_actual).toFixed(2))}</span>
                    </div>
                    <div className={`${styles.dataCell}`}>
                        <span>{el.bpcl_mkt_share_actual !== null && numberFormatEnIn(Number(el.bpcl_mkt_share_actual).toFixed(2))}</span>
                    </div>
                    <div className={`${styles.dataCell}`}>
                        <span>{el.product_segment}</span>
                    </div>
                    <div className={`${styles.dataCell}`}>
                        <span>{el.sales_actual}</span>
                    </div>
                    <div className={`${styles.dataCell}`}>
                        <span>{el.revenue_actual}</span>
                    </div>
                    <div className={`${styles.dataCell}`}>
                        <span>{el.bpcl_mkt_share_actual}</span>
                    </div>
                </div>
            ))} */}
                    {salesRevMarkData &&
                        salesRevMarkData.map((el, index) => (
                            <div className={`d-flex ${styles.tableDataRow}`} key={index}>
                                <div className={`${styles.dataCell} `}>
                                    <span>{el.com}</span>
                                </div>
                                <div className={`${styles.dataCell} `}>
                                    <span>{el.bpcl}</span>
                                </div>
                                <div className={`${styles.dataCell} `}>
                                    <span>{el.iocl}</span>
                                </div>
                                <div className={`${styles.dataCell} `}>
                                    <span>{el.hpcl}</span>
                                </div>
                                <div className={`${styles.dataCell} `}>
                                    <span>{el.shell}</span>
                                </div>
                                <div className={`${styles.dataCell} `}>
                                    <span>{el.ril}</span>
                                </div>
                                <div className={`${styles.dataCell}`}>
                                    <span>{el.nel}</span>
                                </div>
                            </div>
                        ))}

                    {hsseData &&
                        hsseData.map((el, index) => (
                            <div className={`d-flex ${styles.tableDataRow}`} key={index}>
                                <div className={`${styles.dataCell} `}>
                                    <span>{el.plant_name}</span>
                                </div>
                                <div className={`${styles.dataCell} `}>
                                    <span>{el.incidents}</span>
                                </div>
                                <div className={`${styles.dataCell}`}>
                                    <span>{el.lost_man_hours}</span>
                                </div>
                            </div>
                        ))}

                    {plantData &&
                        plantData.map((el, index) => (
                            <div className={`d-flex ${styles.tableDataRow}`} key={index}>
                                <div className={`${styles.dataCell} `}>
                                    <span>{el.plant_name}</span>
                                </div>
                                <div className={`${styles.dataCell} `}>
                                    <span>{el.supply_days.target}</span>
                                </div>
                                <div className={`${styles.dataCell} `}>
                                    <span
                                        style={{
                                            color: `${Math.sign(
                                                el.supply_days.actual - el.supply_days.target
                                            ) === 1
                                                ? '#74ce12'
                                                : '#e13219'
                                                }`,
                                        }}
                                    >
                                        {el.supply_days.actual}
                                    </span>
                                </div>
                                <div className={`${styles.dataCell} `}>
                                    <span>{el.supply.target}</span>
                                </div>
                                <div className={`${styles.dataCell}`}>
                                    <span
                                        style={{
                                            color: `${Math.sign(el.supply.actual - el.supply.target) === 1
                                                ? '#74ce12'
                                                : '#e13219'
                                                }`,
                                        }}
                                    >
                                        {el.supply.actual}
                                    </span>
                                </div>
                            </div>
                        ))}
                    {supplyData &&
                        supplyData.map((el, index) => (
                            <div className={`d-flex ${styles.tableDataRow}`} key={index}>
                                <div className={`${styles.dataCell} `}>
                                    <span>{el.plant_name}</span>
                                </div>
                                <div className={`${styles.dataCell} `}>
                                    <span>{el.supply_days.target}</span>
                                </div>
                                <div className={`${styles.dataCell} `}>
                                    <span
                                        style={{
                                            color: `${Math.sign(
                                                el.supply_days.actual - el.supply_days.target
                                            ) === 1
                                                ? '#74ce12'
                                                : '#e13219'
                                                }`,
                                        }}
                                    >
                                        {el.supply_days.actual}
                                    </span>
                                </div>
                                <div className={`${styles.dataCell} `}>
                                    <span>{el.supply.target}</span>
                                </div>
                                <div className={`${styles.dataCell}`}>
                                    <span
                                        style={{
                                            color: `${Math.sign(el.supply.actual - el.supply.target) === 1
                                                ? '#74ce12'
                                                : '#e13219'
                                                }`,
                                        }}
                                    >
                                        {el.supply.actual}
                                    </span>
                                </div>
                            </div>
                        ))}
                </div>
            </div>
        </div>
    )
}
export default BuRetailMapTable
