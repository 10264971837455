import React, { useRef, useEffect, useState } from 'react'
import Chart from 'chart.js'
import 'chartjs-plugin-datalabels'
import styles from './BuEthanolBlendBarGraph.module.scss'
import { numberFormatEnIn, numberFormatNoSeparator } from '../../../utility/utility'
//import { inrFormat, usdFormat } from '../../../utility/utility'

Chart.defaults.global.defaultFontFamily = 'Titillium Web'

function BuEthanolBlendBarGraph(props) {
    const chartContainer = useRef('barGraph')
    const [chartInstance, setChartInstance] = useState(null)
    const [Graphunit, setGraphunit] = useState('TKL')

    useEffect(() => {
        let actualArray = []
        let targetArray = []
        let targetLabelArray = []

        const xLabels = [
            'Oct',
            'Nov',
            'Dec',
            'Jan',
            'Feb',
            'Mar',
            'Apr',
            'May',
            'Jun',
            'Jul',
            'Aug',
            'Sep',
        ]
        let xTicks = []

        function prepareGraphData(apiGraphData) {
            // console.debug("CURRENT API DATA",apiGraphData)
            apiGraphData.forEach((item, index) => {
                actualArray.push(parseFloat(item.ethanol_sales_mtd).toFixed(2)*3)
                targetArray.push(parseFloat(item.ms_sales_mtd).toFixed(2))
                targetLabelArray.push(parseFloat(item.ethanol_percentage_mtd).toFixed(2))
                setGraphunit(item.unit.toUpperCase())
            })
            xTicks = xLabels.slice(0, apiGraphData.length)
        }

        prepareGraphData(props.graphData)

        if (chartInstance) {
            chartInstance.destroy()
        }
        const ctx = chartContainer.current.getContext('2d')
        let gradientMumbai = ctx.createLinearGradient(0, 0, 0, 500)
        gradientMumbai.addColorStop(0, '#20B010')
        gradientMumbai.addColorStop(1, '#30FF18')
        
        let gradient = ctx.createLinearGradient(0, 0, 0, 450)
        gradient.addColorStop(1, 'rgba(0, 168, 236,0.3)')
        gradient.addColorStop(0, 'rgba(0, 224, 188,0.3)')
//         const SALES_ACTUAL_GRADIENT_START = 'rgba(0, 168, 236, 1)'
// const SALES_ACTUAL_GRADIENT_END = 'rgba(0, 224, 188, 1)'
        let gradientGrm = ''
        let targetBackgroundColor = ''

        ////SET COLORS ACCORDING TO LOCATION
        gradientGrm = gradientMumbai
        targetBackgroundColor = 'rgb(93, 250, 2, 0.4)'

        const chartConfig = {
            type: 'bar',
            data: {
                labels: xLabels,
                datasets: [
                    {
                        xAxisID: 'bar-x-axis1',
                        label: 'MS sale',
                        data: targetArray,
                        categoryPercentage: 1,
                        barPercentage: 0.3,
                        backgroundColor: gradientGrm,
                        borderWidth: 0,
                        datalabels: {
                            // display: false,
                            formatter: function (value, context) {
                                return value > 0 ? `${targetLabelArray[context.dataIndex]}%` : "" 
                          },
                          anchor: 'end',
                          align: 'end',
                          color: '#FFFFFF',
                          font: {
                              size: 16,
                          },
                            // formatter: function (value, context) {
                            //       return value > 0 ? `${targetLabelArray[context.dataIndex]}%` : "" 
                            // },
                            // anchor: 'start',
                            // align: 'end',
                            // color: '#FFFFFF',
                            // font: {
                            //     size: 16,
                            // },
                        },
                    },
                    {
                        xAxisID: 'bar-x-axis2',
                        label: 'Ethanol sale',
                        backgroundColor: targetBackgroundColor,
                        categoryPercentage: 0.7,
                        data: actualArray,
                        borderWidth: 0,
                        pointRadius: 0,
                        datalabels: {
                            display: false,
                            // formatter: function (value, context) {
                            //     console.log("ssss", value, context)
                            //       return value > 0 ? targetLabelArray[context.dataIndex] : 
                            // },
                            // formatter: function (value, context) {
                            //   return value > 0 ? targetLabelArray[context.dataIndex] : ""
                            // },
                            // anchor: "end",
                            // align: 'end',
                            // offset: 0,
                            // color: "rgb(161, 173, 187)",
                            // font: {
                            //   size: 20
                            // }
                        },
                    },
                ],
            },
            options: {
                // barRoundness: 1,
                tooltips: {
                    enabled : true,
                    mode : 'label',
                    callbacks: {
                        label: (tooltipItems, data) => {
                            let ethanolValue
                            ethanolValue = data.datasets[tooltipItems.datasetIndex].label === 'MS sale'? numberFormatEnIn(data.datasets[tooltipItems.datasetIndex].data[tooltipItems.index]) : numberFormatEnIn(data.datasets[tooltipItems.datasetIndex].data[tooltipItems.index]/3)
                            return `${data.datasets[tooltipItems.datasetIndex].label}: ${ethanolValue} ${Graphunit}`
                        }
                    }
                },
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    xAxes: [
                        {
                            type: 'category',

                            display: true,
                            offset: true,
                            id: 'bar-x-axis1',

                            gridLines: {
                                color: 'transparent',
                                offsetGidLines: true,
                                tickMarkLength: 0,
                                borderDash: [3],
                            },
                            ticks: {
                                padding: 9,
                                fontColor: '#dee5ef',
                                fontSize: '22',
                            },
                        },
                        {
                            display: false,
                            offset: true,
                            id: 'bar-x-axis2',
                            type: 'category',

                            gridLines: {
                                color: 'transparent',
                                offsetGidLines: true,
                                tickMarkLength: 0,
                                borderDash: [3],
                            },
                            ticks: {
                                padding: 9,
                                fontColor: '#dee5ef',
                                fontSize: '22',
                            },
                        },
                    ],

                    yAxes: [
                        {
                            beginAtZero: true,
                            ticks: {
                                beginAtZero: true,
                                display: false,
                                padding: 20,
                                fontColor: '#dee5ef',
                                fontSize: '20',
                            },
                            gridLines: {
                                color: 'transparent',
                                tickMarkLength: 0,
                                zeroLineColor: '#67758d',
                                zeroLineWidth: 1,
                            },
                        },
                    ],
                },
                legend: {
                    display: false,
                },
            },
        }

        if (chartContainer && chartContainer.current) {
            const newChartInstance = new Chart(ctx, chartConfig)
            setChartInstance(newChartInstance)
        }
    }, [chartContainer, props.graphData, props.location]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className={`flex-grow-1 ${styles.graphContainer}`}>
            <div className={`${styles.grmGraph}`}>
                <canvas ref={chartContainer} />
                <div className={`${styles.sugarY}`}>Sugar Year</div>
            </div>
        </div>
    )
}
export default BuEthanolBlendBarGraph