import React, { useState, useEffect } from 'react'
import styles from './Map.module.scss'
import { fetchItrmTableDataHs, fetchItrmTableDataLs } from '../../services/Itrm'
import { isEmpty } from 'lodash'
import { Table } from 'react-bootstrap'

const MapTable = (props) => {
    const [location, setLocation] = useState(props.location)
    const [data, setData] = useState([])
    const [lsData, setLsData] = useState([])
    const [dataTotal, setDataTotal] = useState([])
    const [lsDataTotal, setLsDataTotal] = useState({})
    const [hsTotalPlanned, setHsTotalPlanned] = useState([])
    const [hsTotalProcured, setHsTotalProcured] = useState([])
    const [lsTotalPlaned, setLsTotalPlanned] = useState([])
    const [lsTotalProcured, setLsTotalProcured] = useState([])
    const [totalPlannedMRKRBR, setTotalPlannedMRKRBR] = useState([])
    const [totalProcMRKRBR, setTotalProcMRKRBR] = useState([])

    const [LsBinaPlanDataTotal, setLsBinaPlanDataTotal] = useState(0)
    const [LsBinaProcDataTotal, setLsBinaProcDataTotal] = useState(0)
    const [term_hs_procurred, setterm_hs_procurred] = useState(0)
    const [term_ls_procurred, setterm_ls_procurred] = useState(0)
    const [spot_hs_procurred, setspot_hs_procurred] = useState(0)
    const [spot_ls_procurred, setspot_ls_procurred] = useState(0)
    const [indigenous_hs_procurred, setindigenous_hs_procurred] = useState(0)
    const [indigenous_ls_procurred, setindigenous_ls_procurred] = useState(0)
    const [total_hs_procurred, settotal_hs_procurred] = useState(0)
    const [total_ls_procurred, settotal_ls_procurred] = useState(0)

    // function switchLocation() {
    //   if(location === "mumbai") {
    //     setLocation("kochi");
    //   }
    //   if(location === "kochi") {
    //     setLocation("mumbai")
    //   }
    // }

    useEffect(() => {
        let mounted = true
        if(mounted){
        // mapping table data term hs
        let fetchTableDataths = props.data.map(gettermhs => {return gettermhs.term_hs})
        let term_hs_procurred = fetchTableDataths.reduce((a,b) => {return a + b}, 0)
        // mapping table data term ls
        let fetchTableDatatls = props.data.map(gettermhs => {return gettermhs.term_ls})
        let term_ls_procurred = fetchTableDatatls.reduce((a,b) => {return a + b}, 0)
        // mapping table data spot hs
        let fetchTableDatashs = props.data.map(gettermhs => {return gettermhs.spot_hs})
        let spot_hs_procurred = fetchTableDatashs.reduce((a,b) => {return a + b}, 0)
        // mapping table data spot ls
        let fetchTableDatasls = props.data.map(gettermhs => {return gettermhs.spot_ls})
        let spot_ls_procurred = fetchTableDatasls.reduce((a,b) => {return a + b}, 0)
        // mapping table data indigenous hs
        let fetchTableDataihs = props.data.map(gettermhs => {return gettermhs.indigenous_hs})
        let indigenous_hs_procurred = fetchTableDataihs.reduce((a,b) => {return a + b}, 0)
        // mapping table data indigenous ls
        let fetchTableDatails = props.data.map(gettermhs => {return gettermhs.indigenous_ls})
        let indigenous_ls_procurred = fetchTableDatails.reduce((a,b) => {return a + b}, 0)

        let total_hs_procur = term_hs_procurred + spot_hs_procurred + indigenous_hs_procurred
        let total_ls_procur = term_ls_procurred + spot_ls_procurred + indigenous_ls_procurred
    

        setterm_hs_procurred(term_hs_procurred)
        setterm_ls_procurred(term_ls_procurred)
        setspot_hs_procurred(spot_hs_procurred)
        setspot_ls_procurred(spot_ls_procurred)
        setindigenous_hs_procurred(indigenous_hs_procurred)
        setindigenous_ls_procurred(indigenous_ls_procurred)
        settotal_hs_procurred(total_hs_procur)
        settotal_ls_procurred(total_ls_procur)
        }
        
        fetchItrmTableDataHs(props.location, props.regionName, props.year)
            .then((response) => {
                if(mounted){
                    if (response.data.length > 0) {
                        let totalplannedMRKRBR = 0
                        let totalprocuredMRKRBR = 0
                        let totalplanned = 0
                        let totalprocured = 0
                        const regionNameHSTable = {}
                        response.data.forEach((el) => {
                            totalprocured = el.term_procured + el.spot_procured + el.indegenous_procured
                            totalplanned = el.term_planned + el.spot_planned + el.indegenous_planned
                            totalplannedMRKRBR = el.all_ref_total_plan + el.all_ref_total_proc
                            regionNameHSTable[el.region] = el
                        })
                        setTotalPlannedMRKRBR(totalplannedMRKRBR)
                        setHsTotalPlanned(totalplanned)
                        setHsTotalProcured(totalprocured)
                        setData(Object.values(regionNameHSTable))
                        setDataTotal(Object.values(regionNameHSTable)[0])
                    } else {
                        setDataTotal({term_planned: 0,
                            term_procured: 0,
                            spot_planned: 0,
                            spot_procured: 0,
                            indegenous_planned: 0,
                            indegenous_procured: 0,
                        })
                        console.debug('error')
                        return true
                    }
                }
            })
            .catch((error) => console.debug(error))

        fetchItrmTableDataLs(props.location, props.regionName, props.year)
            .then((response) => {
                if(mounted){
                    // console.log(response.data)
                    if (response.data.length > 0) {
                        let totalplanned = 0
                        let totalprocured = 0
                        const regionNameLSTable = {}
                        response.data.forEach((el) => {
                            totalprocured = el.term_procured + el.spot_procured + el.indegenous_procured
                            totalplanned = el.term_planned + el.spot_planned + el.indegenous_planned
                            regionNameLSTable[el.region] = el
                            if (props.location === 'mumbai') {
                                localStorage.setItem('lsBinaPlanTotal', el.all_ref_total_plan)
                                localStorage.setItem('lsBinaProcTotal', el.all_ref_total_proc)
                            }
                        })
                        if (props.location === 'mumbai') {
                            setLsBinaPlanDataTotal(localStorage.getItem("lsBinaPlanTotal"))
                            setLsBinaProcDataTotal(localStorage.getItem("lsBinaProcTotal"))
                        }
                        setLsTotalPlanned(totalplanned)
                        setLsTotalProcured(totalprocured)
                        setLsData(Object.values(regionNameLSTable))
                        setLsDataTotal(Object.values(regionNameLSTable)[0])
                    } else {
                        setLsData(Object.values(0))
                        setLsDataTotal({term_planned: 0,
                            term_procured: 0,
                            spot_planned: 0,
                            spot_procured: 0,
                            indegenous_planned: 0,
                            indegenous_procured: 0,
                            all_ref_total_plan: 0, 
                            all_ref_total_proc: 0,})
                        console.debug('error')
                        return true
                    }
                }
            })
            .catch((error) => console.debug(error))
        setLocation(props.location)
        // getLsKochiTableData();
        // getHSMumbaiTableData();
        return () => {
            mounted = false
        }
    }, [props.location, props.year, props.data])// eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Table variant="dark">
            <thead>
                <tr style={{ backgroundColor:'#0b4958'}}>
                    <th className={`${styles.mapTableHeader}`}>Procurement </th>
                    <th className={`${styles.mapTableHeader}`} colSpan={2}>Term (MMT)</th>
                    <th className={`${styles.mapTableHeader}`} colSpan={2}>Spot (MMT)</th>
                    <th className={`${styles.mapTableHeader}`} colSpan={2}>Indigenous (MMT)</th>
                    <th className={`${styles.mapTableHeader}`} colSpan={2}>Total (MMT)</th>
                    {/* <th className={`${styles.mapTableHeader1}`} colSpan={3}>Total (MMT) MR+KR+BR</th> */}
                </tr>
                <tr style={{ backgroundColor:'#0b4958'}}>
                    <th className={`${styles.mapTableHeader}`}>Product </th>
                    <th className={`${styles.mapTableHeader}`}>Plan.</th>
                    <th className={`${styles.mapTableHeader}`}>Proc.</th>
                    <th className={`${styles.mapTableHeader}`}>Plan.</th>
                    <th className={`${styles.mapTableHeader}`}>Proc.</th>
                    <th className={`${styles.mapTableHeader}`}>Plan.</th>
                    <th className={`${styles.mapTableHeader}`}>Proc.</th>
                    <th className={`${styles.mapTableHeader}`}>Plan.</th>
                    <th className={`${styles.mapTableHeader}`}>Proc.</th>
                    {/* <th className={`${styles.mapTableHeader}`}>Plan.</th>
                    <th className={`${styles.mapTableHeader}`}>Proc.</th> */}
                </tr>
            </thead>
            {
                props.location === 'mumbai' ?
                <tbody>
                    {
                        data.length > 0 ?
                        <tr style={{border: '1px solid black', backgroundColor:'#410d7e'}}>
                            <td style={{fontSize: '22px'}} className={styles.mapTableBody}>HS</td>
                            {data.map((el, index) => (
                                <td className={styles.mapTableBody} key='hs_term_planned'>
                                    {Number(el.term_planned).toFixed(2)}
                                </td>
                            ))}
                            {data.map((el, index) => (
                                <td className={styles.mapTableBody} key='hs_term_procured'>
                                    {/* {Number(term_hs_procurred).toFixed(2)} */}
                                    {Number(el.term_procured).toFixed(2)}
                                </td>
                            ))}
                            {data.map((el, index) => (
                                <td className={styles.mapTableBody} key='hs_spot_planned'>
                                    {Number(el.spot_planned).toFixed(2)}
                                </td>
                            ))}
                            {data.map((el, index) => (
                                <td className={styles.mapTableBody} key='hs_spot_procured'>
                                    {/* {Number(spot_hs_procurred).toFixed(2)} */}
                                    {Number(el.spot_procured).toFixed(2)}
                                </td>
                            ))}
                            {data.map((el, index) => (
                                <td className={styles.mapTableBody} key='hs_indegenous_planned'>
                                    {Number(el.indegenous_planned).toFixed(2)}
                                </td>
                            ))}
                            {data.map((el, index) => (
                                <td className={styles.mapTableBody} key='hs_indegenous_procured'>
                                    {/* {Number(indigenous_hs_procurred).toFixed(2)} */}
                                    {Number(el.indegenous_procured).toFixed(2)}
                                </td>
                            ))}
                            <td className={styles.mapTableBody}>
                                {Number(hsTotalPlanned).toFixed(2)}
                            </td>
                            <td className={styles.mapTableBody}>
                                {/* {Number(total_hs_procurred).toFixed(2)} */}
                                {Number(hsTotalProcured).toFixed(2)}
                            </td>
                        </tr> : <tr style={{border: '1px solid black', backgroundColor:'#410d7e'}}>
                            <td style={{fontSize: '22px'}} className={styles.mapTableBody}>HS</td>
                            <td className={styles.mapTableBody}>----</td>
                            <td className={styles.mapTableBody}>----</td>
                            <td className={styles.mapTableBody}>----</td>
                            <td className={styles.mapTableBody}>----</td>
                            <td className={styles.mapTableBody}>----</td>
                            <td className={styles.mapTableBody}>----</td>
                            <td className={styles.mapTableBody}>----</td>
                            <td className={styles.mapTableBody}>----</td>
                        </tr>
                    }
                    {
                        lsData.length > 0 ?
                        <tr style={{border: '1px solid black', backgroundColor:'#064495'}}>
                            <td style={{fontSize: '22px'}} className={styles.mapTableBody}>LS</td>
                            {lsData.map((el, index) => (
                                <td className={styles.mapTableBody} key='ls_term_planned'>
                                    {Number(el.term_planned).toFixed(2)}
                                </td>
                            ))}
                            {lsData.map((el, index) => (
                                <td className={styles.mapTableBody} key='ls_term_procured'>
                                    {/* {Number(term_ls_procurred).toFixed(2)} */}
                                    {Number(el.term_procured).toFixed(2)}
                                </td>
                            ))}
                            {lsData.map((el, index) => (
                                <td className={styles.mapTableBody} key='ls_spot_planned'>
                                    {Number(el.spot_planned).toFixed(2)}
                                </td>
                            ))}
                            {lsData.map((el, index) => (
                                <td className={styles.mapTableBody} key='ls_spot_procured'>
                                    {/* {Number(spot_ls_procurred).toFixed(2)} */}
                                    {Number(el.spot_procured).toFixed(2)}
                                </td>
                            ))}
                            {lsData.map((el, index) => (
                                <td className={styles.mapTableBody} key='ls_indegenous_planned'>
                                    {Number(el.indegenous_planned).toFixed(2)}
                                </td>
                            ))}
                            {lsData.map((el, index) => (
                                <td className={styles.mapTableBody} key='ls_indegenous_procured'>
                                    {/* {Number(indigenous_ls_procurred).toFixed(2)} */}
                                    {Number(el.indegenous_procured).toFixed(2)}
                                </td>
                            ))}
                            <td className={styles.mapTableBody}>
                                {Number(lsTotalPlaned).toFixed(2)}
                            </td>
                            <td className={styles.mapTableBody}>
                                {/* {Number(total_ls_procurred).toFixed(2)} */}
                                {Number(lsTotalProcured).toFixed(2)}
                            </td>
                        </tr> : <tr style={{border: '1px solid black', backgroundColor:'#064495'}}>
                            <td style={{fontSize: '22px'}} className={styles.mapTableBody}>LS</td>
                            <td className={styles.mapTableBody}>----</td>
                            <td className={styles.mapTableBody}>----</td>
                            <td className={styles.mapTableBody}>----</td>
                            <td className={styles.mapTableBody}>----</td>
                            <td className={styles.mapTableBody}>----</td>
                            <td className={styles.mapTableBody}>----</td>
                            <td className={styles.mapTableBody}>----</td>
                            <td className={styles.mapTableBody}>----</td>
                        </tr>
                    }
                    {
                        typeof lsDataTotal !== null  || typeof dataTotal !== null?
                        <tr style={{border: '1px solid black', backgroundColor:'#410d7e'}}>
                            <td style={{fontSize: '22px'}} className={styles.mapTableBody}>TOTAL</td>
                                <td className={styles.mapTableBody} key='ls_term_planned'>
                                    {Number(dataTotal.term_planned + lsDataTotal.term_planned).toFixed(2)}
                                </td>
                            
                                <td className={styles.mapTableBody} key='ls_term_procured'>
                                    {/* {Number(term_ls_procurred).toFixed(2)} */}
                                    {Number(dataTotal.term_procured + lsDataTotal.term_procured).toFixed(2)}
                                </td>
                            
                                <td className={styles.mapTableBody} key='ls_spot_planned'>
                                    {Number(dataTotal.spot_planned + lsDataTotal.spot_planned).toFixed(2)}
                                </td>
                            
                                <td className={styles.mapTableBody} key='ls_spot_procured'>
                                    {/* {Number(spot_ls_procurred).toFixed(2)} */}
                                    {Number(dataTotal.spot_procured + lsDataTotal.spot_procured).toFixed(2)}
                                </td>
                            
                                <td className={styles.mapTableBody} key='ls_indegenous_planned'>
                                    {Number(dataTotal.indegenous_planned + lsDataTotal.indegenous_planned).toFixed(2)}
                                </td>
                            
                                <td className={styles.mapTableBody} key='ls_indegenous_procured'>
                                    {/* {Number(indigenous_ls_procurred).toFixed(2)} */}
                                    {Number(dataTotal.indegenous_procured + lsDataTotal.indegenous_procured).toFixed(2)}
                                </td>
                            
                            <td className={styles.mapTableBody}>
                                {Number(dataTotal.term_planned + lsDataTotal.term_planned + dataTotal.spot_planned + lsDataTotal.spot_planned + dataTotal.indegenous_planned + lsDataTotal.indegenous_planned).toFixed(2)}
                            </td>
                            <td className={styles.mapTableBody}>
                                {/* {Number(total_ls_procurred).toFixed(2)} */}
                                {Number(dataTotal.term_procured + lsDataTotal.term_procured + dataTotal.spot_procured + lsDataTotal.spot_procured + dataTotal.indegenous_procured + lsDataTotal.indegenous_procured).toFixed(2)}
                            </td>
                            {/* <td className={styles.mapTableBody}>
                            {Number(dataTotal.all_ref_total_plan + lsDataTotal.all_ref_total_plan).toFixed(2)}
                          </td>
                            
                            <td className={styles.mapTableBody}>
                            {Number(dataTotal.all_ref_total_proc + lsDataTotal.all_ref_total_proc).toFixed(2)}
                     </td> */}
                        </tr> : <tr style={{border: '1px solid black', backgroundColor:'#410d7e'}}>
                            <td style={{fontSize: '22px'}} className={styles.mapTableBody}>TOTAL</td>
                            <td className={styles.mapTableBody}>----</td>
                            <td className={styles.mapTableBody}>----</td>
                            <td className={styles.mapTableBody}>----</td>
                            <td className={styles.mapTableBody}>----</td>
                            <td className={styles.mapTableBody}>----</td>
                            <td className={styles.mapTableBody}>----</td>
                            <td className={styles.mapTableBody}>----</td>
                            <td className={styles.mapTableBody}>----</td>
                        </tr>
                    }
                    
                </tbody> : props.location === "kochi" ?
                <tbody>
                {
                    data.length > 0 ?
                    <tr style={{border: '1px solid black', backgroundColor:'#410d7e'}}>
                        <td style={{fontSize: '22px'}} className={styles.mapTableBody}>HS</td>
                        {data.map((el, index) => (
                            <td className={styles.mapTableBody} key='hs_term_planned'>
                                {Number(el.term_planned).toFixed(2)}
                            </td>
                        ))}
                        {data.map((el, index) => (
                            <td className={styles.mapTableBody} key='hs_term_procured'>
                                {/* {Number(term_hs_procurred).toFixed(2)} */}
                                {Number(el.term_procured).toFixed(2)}
                            </td>
                        ))}
                        {data.map((el, index) => (
                            <td className={styles.mapTableBody} key='hs_spot_planned'>
                                {Number(el.spot_planned).toFixed(2)}
                            </td>
                        ))}
                        {data.map((el, index) => (
                            <td className={styles.mapTableBody} key='hs_spot_procured'>
                                {/* {Number(spot_hs_procurred).toFixed(2)} */}
                                {Number(el.spot_procured).toFixed(2)}
                            </td>
                        ))}
                        {data.map((el, index) => (
                            <td className={styles.mapTableBody} key='hs_indegenous_planned'>
                                {Number(el.indegenous_planned).toFixed(2)}
                            </td>
                        ))}
                        {data.map((el, index) => (
                            <td className={styles.mapTableBody} key='hs_indegenous_procured'>
                                {/* {Number(indigenous_hs_procurred).toFixed(2)} */}
                                {Number(el.indegenous_procured).toFixed(2)}
                            </td>
                        ))}
    
                        <td className={styles.mapTableBody}>
                            {Number(hsTotalPlanned).toFixed(2)}
                        </td>
                        <td className={styles.mapTableBody}>
                            {/* {Number(total_hs_procurred).toFixed(2)} */}
                            {Number(hsTotalProcured).toFixed(2)}
                        </td>
                    </tr> : <tr style={{border: '1px solid black',backgroundColor:'#410d7e'}}>
                        <td style={{fontSize: '22px'}} className={styles.mapTableBody}>HS</td>
                        <td className={styles.mapTableBody}>----</td>
                        <td className={styles.mapTableBody}>----</td>
                        <td className={styles.mapTableBody}>----</td>
                        <td className={styles.mapTableBody}>----</td>
                        <td className={styles.mapTableBody}>----</td>
                        <td className={styles.mapTableBody}>----</td>
                        <td className={styles.mapTableBody}>----</td>
                        <td className={styles.mapTableBody}>----</td>
                    </tr>
                }
                {
                    lsData.length > 0 ?
                    <tr style={{border: '1px solid black', backgroundColor:'#064495'}}>
                        <td style={{fontSize: '22px'}} className={styles.mapTableBody}>LS</td>
                        {lsData.map((el, index) => (
                            <td className={styles.mapTableBody} key='ls_term_planned'>
                                {Number(el.term_planned).toFixed(2)}
                            </td>
                        ))}
                        {lsData.map((el, index) => (
                            <td className={styles.mapTableBody} key='ls_term_procured'>
                                {/* {Number(term_ls_procurred).toFixed(2)} */}
                                {Number(el.term_procured).toFixed(2)}
                            </td>
                        ))}
                        {lsData.map((el, index) => (
                            <td className={styles.mapTableBody} key='ls_spot_planned'>
                                {Number(el.spot_planned).toFixed(2)}
                            </td>
                        ))}
                        {lsData.map((el, index) => (
                            <td className={styles.mapTableBody} key='ls_spot_procured'>
                                {/* {Number(spot_ls_procurred).toFixed(2)} */}
                                {Number(el.spot_procured).toFixed(2)}
                            </td>
                        ))}
                        {lsData.map((el, index) => (
                            <td className={styles.mapTableBody} key='ls_indegenous_planned'>
                                {Number(el.indegenous_planned).toFixed(2)}
                            </td>
                        ))}
                        {lsData.map((el, index) => (
                            <td className={styles.mapTableBody} key='ls_indegenous_procured'>
                                {/* {Number(indigenous_ls_procurred).toFixed(2)} */}
                                {Number(el.indegenous_procured).toFixed(2)}
                            </td>
                        ))}
    
                        <td className={styles.mapTableBody}>
                            {Number(lsTotalPlaned).toFixed(2)}
                        </td>
                        <td className={styles.mapTableBody}>
                            {/* {Number(total_ls_procurred).toFixed(2)} */}
                            {Number(lsTotalProcured).toFixed(2)}
                        </td>
                    </tr> : <tr style={{border: '1px solid black', backgroundColor:'#064495'}}>
                        <td style={{fontSize: '22px'}} className={styles.mapTableBody}>LS</td>
                        <td className={styles.mapTableBody}>----</td>
                        <td className={styles.mapTableBody}>----</td>
                        <td className={styles.mapTableBody}>----</td>
                        <td className={styles.mapTableBody}>----</td>
                        <td className={styles.mapTableBody}>----</td>
                        <td className={styles.mapTableBody}>----</td>
                        <td className={styles.mapTableBody}>----</td>
                        <td className={styles.mapTableBody}>----</td>
                    </tr>
                }
                {
                    typeof lsDataTotal !== null  || typeof dataTotal !== null?
                    <tr style={{border: '1px solid black', backgroundColor:'#410d7e'}}>
                        <td style={{fontSize: '22px'}} className={styles.mapTableBody}>TOTAL</td>
                            <td className={styles.mapTableBody} key='ls_term_planned'>
                                {Number(dataTotal.term_planned + lsDataTotal.term_planned).toFixed(2)}
                            </td>
                        
                            <td className={styles.mapTableBody} key='ls_term_procured'>
                                {/* {Number(term_ls_procurred).toFixed(2)} */}
                                {Number(dataTotal.term_procured + lsDataTotal.term_procured).toFixed(2)}
                            </td>
                        
                            <td className={styles.mapTableBody} key='ls_spot_planned'>
                                {Number(dataTotal.spot_planned + lsDataTotal.spot_planned).toFixed(2)}
                            </td>
                        
                            <td className={styles.mapTableBody} key='ls_spot_procured'>
                                {/* {Number(spot_ls_procurred).toFixed(2)} */}
                                {Number(dataTotal.spot_procured + lsDataTotal.spot_procured).toFixed(2)}
                            </td>
                        
                            <td className={styles.mapTableBody} key='ls_indegenous_planned'>
                                {Number(dataTotal.indegenous_planned + lsDataTotal.indegenous_planned).toFixed(2)}
                            </td>
                        
                            <td className={styles.mapTableBody} key='ls_indegenous_procured'>
                                {/* {Number(indigenous_ls_procurred).toFixed(2)} */}
                                {Number(dataTotal.indegenous_procured + lsDataTotal.indegenous_procured).toFixed(2)}
                            </td>
                        
                        <td className={styles.mapTableBody}>
                            {Number(dataTotal.term_planned + lsDataTotal.term_planned + dataTotal.spot_planned + lsDataTotal.spot_planned + dataTotal.indegenous_planned + lsDataTotal.indegenous_planned).toFixed(2)}
                        </td>
                        <td className={styles.mapTableBody}>
                            {/* {Number(total_ls_procurred).toFixed(2)} */}
                            {Number(dataTotal.term_procured + lsDataTotal.term_procured + dataTotal.spot_procured + lsDataTotal.spot_procured + dataTotal.indegenous_procured + lsDataTotal.indegenous_procured).toFixed(2)}
                        </td>
                                {/* <td className={styles.mapTableBody}>
                                    {Number(dataTotal.all_ref_total_plan + lsDataTotal.all_ref_total_plan).toFixed(2)}                                    </td>
                                <td className={styles.mapTableBody}>
                                    {Number(dataTotal.all_ref_total_proc + lsDataTotal.all_ref_total_proc).toFixed(2)}
                                </td> */}
                    </tr> : <tr style={{border: '1px solid black', backgroundColor:'#410d7e'}}>
                        <td style={{fontSize: '22px'}} className={styles.mapTableBody}>TOTAL</td>
                        <td className={styles.mapTableBody}>----</td>
                        <td className={styles.mapTableBody}>----</td>
                        <td className={styles.mapTableBody}>----</td>
                        <td className={styles.mapTableBody}>----</td>
                        <td className={styles.mapTableBody}>----</td>
                        <td className={styles.mapTableBody}>----</td>
                        <td className={styles.mapTableBody}>----</td>
                        <td className={styles.mapTableBody}>----</td>
                    </tr>
                }
                
            </tbody> : props.location === "bina" ? <tbody>
                    {
                        data.length > 0 ?
                        <tr style={{border: '1px solid black', backgroundColor:'#410d7e'}}>
                            <td style={{fontSize: '22px'}} className={styles.mapTableBody}>HS</td>
                            {data.map((el, index) => (
                                <td className={styles.mapTableBody} key='hs_term_planned'>
                                    {Number(el.term_planned).toFixed(2)}
                                </td>
                            ))}
                            {data.map((el, index) => (
                                <td className={styles.mapTableBody} key='hs_term_procured'>
                                    {/* {Number(term_hs_procurred).toFixed(2)} */}
                                    {Number(el.term_procured).toFixed(2)}
                                </td>
                            ))}
                            {data.map((el, index) => (
                                <td className={styles.mapTableBody} key='hs_spot_planned'>
                                    {Number(el.spot_planned).toFixed(2)}
                                </td>
                            ))}
                            {data.map((el, index) => (
                                <td className={styles.mapTableBody} key='hs_spot_procured'>
                                    {/* {Number(spot_hs_procurred).toFixed(2)} */}
                                    {Number(el.spot_procured).toFixed(2)}
                                </td>
                            ))}
                            {data.map((el, index) => (
                                <td className={styles.mapTableBody} key='hs_indegenous_planned'>
                                    {Number(el.indegenous_planned).toFixed(2)}
                                </td>
                            ))}
                            {data.map((el, index) => (
                                <td className={styles.mapTableBody} key='hs_indegenous_procured'>
                                    {/* {Number(indigenous_hs_procurred).toFixed(2)} */}
                                    {Number(el.indegenous_procured).toFixed(2)}
                                </td>
                            ))}
        
                            <td className={styles.mapTableBody}>
                                {Number(hsTotalPlanned).toFixed(2)}
                            </td>
                            <td className={styles.mapTableBody}>
                                {/* {Number(total_hs_procurred).toFixed(2)} */}
                                {Number(hsTotalProcured).toFixed(2)}
                            </td>
                        </tr> : <tr style={{border: '1px solid black',backgroundColor:'#410d7e'}}>
                            <td style={{fontSize: '22px'}} className={styles.mapTableBody}>HS</td>
                            <td className={styles.mapTableBody}>----</td>
                            <td className={styles.mapTableBody}>----</td>
                            <td className={styles.mapTableBody}>----</td>
                            <td className={styles.mapTableBody}>----</td>
                            <td className={styles.mapTableBody}>----</td>
                            <td className={styles.mapTableBody}>----</td>
                            <td className={styles.mapTableBody}>----</td>
                        </tr>
                    }
                    { 
                        lsData.length > 0 ?
                        <tr style={{border: '1px solid black', backgroundColor:'#064495'}}>
                            <td style={{fontSize: '22px'}} className={styles.mapTableBody}>LS</td>
                            {lsData.map((el, index) => (
                                <td className={styles.mapTableBody} key='ls_term_planned'>
                                    {Number(el.term_planned).toFixed(2)}
                                </td>
                            ))}
                            {lsData.map((el, index) => (
                                <td className={styles.mapTableBody} key='ls_term_procured'>
                                    {/* {Number(term_ls_procurred).toFixed(2)} */}
                                    {Number(el.term_procured).toFixed(2)}
                                </td>
                            ))}
                            {lsData.map((el, index) => (
                                <td className={styles.mapTableBody} key='ls_spot_planned'>
                                    {Number(el.spot_planned).toFixed(2)}
                                </td>
                            ))}
                            {lsData.map((el, index) => (
                                <td className={styles.mapTableBody} key='ls_spot_procured'>
                                    {/* {Number(spot_ls_procurred).toFixed(2)} */}
                                    {Number(el.spot_procured).toFixed(2)}
                                </td>
                            ))}
                            {lsData.map((el, index) => (
                                <td className={styles.mapTableBody} key='ls_indegenous_planned'>
                                    {Number(el.indegenous_planned).toFixed(2)}
                                </td>
                            ))}
                            {lsData.map((el, index) => (
                                <td className={styles.mapTableBody} key='ls_indegenous_procured'>
                                    {/* {Number(indigenous_ls_procurred).toFixed(2)} */}
                                    {Number(el.indegenous_procured).toFixed(2)}
                                </td>
                            ))}
        
                            <td className={styles.mapTableBody}>
                                {Number(lsTotalPlaned).toFixed(2)}
                            </td>
                            <td className={styles.mapTableBody}>
                                {/* {Number(total_ls_procurred).toFixed(2)} */}
                                {Number(lsTotalProcured).toFixed(2)}
                            </td>
                        </tr> : <tr style={{border: '1px solid black', backgroundColor:'#064495'}}>
                            <td style={{fontSize: '22px'}} className={styles.mapTableBody}>LS</td>
                            <td className={styles.mapTableBody}>----</td>
                            <td className={styles.mapTableBody}>----</td>
                            <td className={styles.mapTableBody}>----</td>
                            <td className={styles.mapTableBody}>----</td>
                            <td className={styles.mapTableBody}>----</td>
                            <td className={styles.mapTableBody}>----</td>
                            <td className={styles.mapTableBody}>----</td>
                            <td className={styles.mapTableBody}>----</td>
                        </tr>
                    }
                    {
                        typeof lsDataTotal !== null  || typeof dataTotal !== null?
                        <tr style={{border: '1px solid black', backgroundColor:'#410d7e'}}>
                            <td style={{fontSize: '22px'}} className={styles.mapTableBody}>TOTAL</td>
                                <td className={styles.mapTableBody} key='ls_term_planned'>
                                    {Number(dataTotal.term_planned + lsDataTotal.term_planned).toFixed(2)}
                                </td>
                            
                                <td className={styles.mapTableBody} key='ls_term_procured'>
                                    {/* {Number(term_ls_procurred).toFixed(2)} */}
                                    {Number(dataTotal.term_procured + lsDataTotal.term_procured).toFixed(2)}
                                </td>
                            
                                <td className={styles.mapTableBody} key='ls_spot_planned'>
                                    {Number(dataTotal.spot_planned + lsDataTotal.spot_planned).toFixed(2)}
                                </td>
                            
                                <td className={styles.mapTableBody} key='ls_spot_procured'>
                                    {/* {Number(spot_ls_procurred).toFixed(2)} */}
                                    {Number(dataTotal.spot_procured + lsDataTotal.spot_procured).toFixed(2)}
                                </td>
                            
                                <td className={styles.mapTableBody} key='ls_indegenous_planned'>
                                    {Number(dataTotal.indegenous_planned + lsDataTotal.indegenous_planned).toFixed(2)}
                                </td>
                            
                                <td className={styles.mapTableBody} key='ls_indegenous_procured'>
                                    {/* {Number(indigenous_ls_procurred).toFixed(2)} */}
                                    {Number(dataTotal.indegenous_procured + lsDataTotal.indegenous_procured).toFixed(2)}
                                </td>
                            
                            <td className={styles.mapTableBody}>
                                {Number(dataTotal.term_planned + lsDataTotal.term_planned + dataTotal.spot_planned + lsDataTotal.spot_planned + dataTotal.indegenous_planned + lsDataTotal.indegenous_planned).toFixed(2)}
                            </td>
                            <td className={styles.mapTableBody}>
                                {/* {Number(total_ls_procurred).toFixed(2)} */}
                                {Number(dataTotal.term_procured + lsDataTotal.term_procured + dataTotal.spot_procured + lsDataTotal.spot_procured + dataTotal.indegenous_procured + lsDataTotal.indegenous_procured).toFixed(2)}
                            </td>
                        </tr> : <tr style={{border: '1px solid black', backgroundColor:'#410d7e'}}>
                            <td style={{fontSize: '22px'}} className={styles.mapTableBody}>TOTAL</td>
                            <td className={styles.mapTableBody}>----</td>
                            <td className={styles.mapTableBody}>----</td>
                            <td className={styles.mapTableBody}>----</td>
                            <td className={styles.mapTableBody}>----</td>
                            <td className={styles.mapTableBody}>----</td>
                            <td className={styles.mapTableBody}>----</td>
                            <td className={styles.mapTableBody}>----</td>
                            <td className={styles.mapTableBody}>----</td>
                        </tr>
                    }
                    
                </tbody> : <tbody>
                    {
                        data.length > 0 ?
                        <tr style={{border: '1px solid black', backgroundColor:'#410d7e'}}>
                            <td style={{fontSize: '22px'}} className={styles.mapTableBody}>HS</td>
                            {data.map((el, index) => (
                                <td className={styles.mapTableBody} key='hs_term_planned'>
                                    {Number(el.term_planned).toFixed(2)}
                                </td>
                            ))}
                            {data.map((el, index) => (
                                <td className={styles.mapTableBody} key='hs_term_procured'>
                                    {/* {Number(term_hs_procurred).toFixed(2)} */}
                                    {Number(el.term_procured).toFixed(2)}
                                </td>
                            ))}
                            {data.map((el, index) => (
                                <td className={styles.mapTableBody} key='hs_spot_planned'>
                                    {Number(el.spot_planned).toFixed(2)}
                                </td>
                            ))}
                            {data.map((el, index) => (
                                <td className={styles.mapTableBody} key='hs_spot_procured'>
                                    {/* {Number(spot_hs_procurred).toFixed(2)} */}
                                    {Number(el.spot_procured).toFixed(2)}
                                </td>
                            ))}
                            {data.map((el, index) => (
                                <td className={styles.mapTableBody} key='hs_indegenous_planned'>
                                    {Number(el.indegenous_planned).toFixed(2)}
                                </td>
                            ))}
                            {data.map((el, index) => (
                                <td className={styles.mapTableBody} key='hs_indegenous_procured'>
                                    {/* {Number(indigenous_hs_procurred).toFixed(2)} */}
                                    {Number(el.indegenous_procured).toFixed(2)}
                                </td>
                            ))}
        
                            <td className={styles.mapTableBody}>
                                {Number(hsTotalPlanned).toFixed(2)}
                            </td>
                            <td className={styles.mapTableBody}>
                                {/* {Number(total_hs_procurred).toFixed(2)} */}
                                {Number(hsTotalProcured).toFixed(2)}
                            </td>
                        </tr> : <tr style={{border: '1px solid black',backgroundColor:'#410d7e'}}>
                            <td style={{fontSize: '22px'}} className={styles.mapTableBody}>HS</td>
                            <td className={styles.mapTableBody}>----</td>
                            <td className={styles.mapTableBody}>----</td>
                            <td className={styles.mapTableBody}>----</td>
                            <td className={styles.mapTableBody}>----</td>
                            <td className={styles.mapTableBody}>----</td>
                            <td className={styles.mapTableBody}>----</td>
                            <td className={styles.mapTableBody}>----</td>
                        </tr>
                    }
                    { 
                        lsData.length > 0 ?
                        <tr style={{border: '1px solid black', backgroundColor:'#064495'}}>
                            <td style={{fontSize: '22px'}} className={styles.mapTableBody}>LS</td>
                            {lsData.map((el, index) => (
                                <td className={styles.mapTableBody} key='ls_term_planned'>
                                    {Number(el.term_planned).toFixed(2)}
                                </td>
                            ))}
                            {lsData.map((el, index) => (
                                <td className={styles.mapTableBody} key='ls_term_procured'>
                                    {/* {Number(term_ls_procurred).toFixed(2)} */}
                                    {Number(el.term_procured).toFixed(2)}
                                </td>
                            ))}
                            {lsData.map((el, index) => (
                                <td className={styles.mapTableBody} key='ls_spot_planned'>
                                    {Number(el.spot_planned).toFixed(2)}
                                </td>
                            ))}
                            {lsData.map((el, index) => (
                                <td className={styles.mapTableBody} key='ls_spot_procured'>
                                    {/* {Number(spot_ls_procurred).toFixed(2)} */}
                                    {Number(el.spot_procured).toFixed(2)}
                                </td>
                            ))}
                            {lsData.map((el, index) => (
                                <td className={styles.mapTableBody} key='ls_indegenous_planned'>
                                    {Number(el.indegenous_planned).toFixed(2)}
                                </td>
                            ))}
                            {lsData.map((el, index) => (
                                <td className={styles.mapTableBody} key='ls_indegenous_procured'>
                                    {/* {Number(indigenous_ls_procurred).toFixed(2)} */}
                                    {Number(el.indegenous_procured).toFixed(2)}
                                </td>
                            ))}
        
                            <td className={styles.mapTableBody}>
                                {Number(lsTotalPlaned).toFixed(2)}
                            </td>
                            <td className={styles.mapTableBody}>
                                {/* {Number(total_ls_procurred).toFixed(2)} */}
                                {Number(lsTotalProcured).toFixed(2)}
                            </td>
                        </tr> : <tr style={{border: '1px solid black', backgroundColor:'#064495'}}>
                            <td style={{fontSize: '22px'}} className={styles.mapTableBody}>LS</td>
                            <td className={styles.mapTableBody}>----</td>
                            <td className={styles.mapTableBody}>----</td>
                            <td className={styles.mapTableBody}>----</td>
                            <td className={styles.mapTableBody}>----</td>
                            <td className={styles.mapTableBody}>----</td>
                            <td className={styles.mapTableBody}>----</td>
                            <td className={styles.mapTableBody}>----</td>
                            <td className={styles.mapTableBody}>----</td>
                        </tr>
                    }
                    {
                        typeof lsDataTotal !== null  || typeof dataTotal !== null?
                        <tr style={{border: '1px solid black', backgroundColor:'#410d7e'}}>
                            <td style={{fontSize: '22px'}} className={styles.mapTableBody}>TOTAL</td>
                                <td className={styles.mapTableBody} key='ls_term_planned'>
                                    {Number(dataTotal.term_planned + lsDataTotal.term_planned).toFixed(2)}
                                </td>
                            
                                <td className={styles.mapTableBody} key='ls_term_procured'>
                                    {/* {Number(term_ls_procurred).toFixed(2)} */}
                                    {Number(dataTotal.term_procured + lsDataTotal.term_procured).toFixed(2)}
                                </td>
                            
                                <td className={styles.mapTableBody} key='ls_spot_planned'>
                                    {Number(dataTotal.spot_planned + lsDataTotal.spot_planned).toFixed(2)}
                                </td>
                            
                                <td className={styles.mapTableBody} key='ls_spot_procured'>
                                    {/* {Number(spot_ls_procurred).toFixed(2)} */}
                                    {Number(dataTotal.spot_procured + lsDataTotal.spot_procured).toFixed(2)}
                                </td>
                            
                                <td className={styles.mapTableBody} key='ls_indegenous_planned'>
                                    {Number(dataTotal.indegenous_planned + lsDataTotal.indegenous_planned).toFixed(2)}
                                </td>
                            
                                <td className={styles.mapTableBody} key='ls_indegenous_procured'>
                                    {/* {Number(indigenous_ls_procurred).toFixed(2)} */}
                                    {Number(dataTotal.indegenous_procured + lsDataTotal.indegenous_procured).toFixed(2)}
                                </td>
                            
                            <td className={styles.mapTableBody}>
                                {Number(dataTotal.term_planned + lsDataTotal.term_planned + dataTotal.spot_planned + lsDataTotal.spot_planned + dataTotal.indegenous_planned + lsDataTotal.indegenous_planned).toFixed(2)}
                            </td>
                            <td className={styles.mapTableBody}>
                                {/* {Number(total_ls_procurred).toFixed(2)} */}
                                {Number(dataTotal.term_procured + lsDataTotal.term_procured + dataTotal.spot_procured + lsDataTotal.spot_procured + dataTotal.indegenous_procured + lsDataTotal.indegenous_procured).toFixed(2)}
                            </td>
                        </tr> : <tr style={{border: '1px solid black', backgroundColor:'#410d7e'}}>
                            <td style={{fontSize: '22px'}} className={styles.mapTableBody}>TOTAL</td>
                            <td className={styles.mapTableBody}>----</td>
                            <td className={styles.mapTableBody}>----</td>
                            <td className={styles.mapTableBody}>----</td>
                            <td className={styles.mapTableBody}>----</td>
                            <td className={styles.mapTableBody}>----</td>
                            <td className={styles.mapTableBody}>----</td>
                            <td className={styles.mapTableBody}>----</td>
                            <td className={styles.mapTableBody}>----</td>
                        </tr>
                    }
                    
                </tbody>
            }
        </Table>
    )
}

export default MapTable
