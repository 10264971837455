import React, { useEffect, useState, useCallback } from 'react'
import expandIcon from '../../../assets/images/expandIcon.png'
import styles from './BuHsse.module.scss'
import { FullScreen, useFullScreenHandle } from 'react-full-screen'
import { fetchRetailHsse, fetchupdatedStatus} from '../../../services/bu'
import { isEmpty, isFinite } from 'lodash'
import { NoDataFound } from '../../DataNotFound/nodatafound'
import { numberFormatNoSeparator } from '../../../utility/utility'
import greenTick from '../../../assets/images/greenTick.png'
import "../../../Common.scss"

const BuHsse = (props) => {
    const [isNoDataAvailable, setIsNoDataAvailable] = useState(false)
    const [esaData, setEsaData] = useState({})
    const [isaData, setIsaData] = useState({})
    const [complianceData, setComplianceData] = useState({})
    const [ltifrData, setLtifrData] = useState({})
    const [incidentData, setIncidentData] = useState({})
    const [nearmissData, setNearmissData] = useState({})
    const [updatedStatus, setUpdatedStatus] = useState('')

    const handle = useFullScreenHandle()

    const [attr, setattr] = useState('handleEnter')
    const fullScrn = () => {
        if (attr === 'handleEnter') {
            setattr('handleExit')
            handle.enter()
            document.body.style.zoom = (window.innerWidth / window.outerWidth)
        } else if (attr === 'handleExit') {
            setattr('handleEnter')
            handle.exit()
            document.body.style.zoom = 0
        }
    }
    const reportChange = useCallback((state) => {
        try {
            if (state === false) {
                document.body.style.zoom = 0
            }
        } catch (exception) {
            console.debug(exception)
        }
    }, [handle]);

    useEffect(() => {
        fetchupdatedStatus(props.year)
        .then(response => {
            // console.log(response)
            if (!isEmpty(response)) {
            response.filter((res) => res.entity === "RETAIL" && res.kpi_name === "HSSE" ? setUpdatedStatus(res.status) : false);
            }else{
                setUpdatedStatus("Not Found")
            }
        })
        .catch(error => console.debug(error))
        fetchRetailHsse(props.year)
            .then(response => {
                if (isEmpty(response.data)) {
                    setIsNoDataAvailable(true)
                } else {
                    response.data.forEach((item) => {
                        if (item.kpi_name === 'Pending Recommendation ESA') { setEsaData(item) }
                        if (item.kpi_name === 'Pending Recommendation ISA') { setIsaData(item) }
                        if (item.kpi_name === 'CAPA Compliance') { setComplianceData(item) }
                        if (item.kpi_name === 'LTIFR') { setLtifrData(item) }
                        if (item.kpi_name === 'Incidents Reported') { setIncidentData(item) }
                        if (item.kpi_name === 'Nearmiss Reported') { setNearmissData(item) }
                    })
                    setIsNoDataAvailable(false)
                }
            })
            .catch(error => console.debug(error))
    }, [props.year])

    const total = (incidentData.minor || 0) + (incidentData.serious || 0) + (incidentData.major || 0)
    const minorPercentage = ((incidentData.minor || 0) * 100) / total
    const seriousPercentage = ((incidentData.serious || 0) * 100) / total
    const majorPercentage = ((incidentData.major || 0) * 100) / total
    const othervalue = (incidentData.actual || 0) - total
    const otherpercentage = ((othervalue || 0) * 100) / total

    return (
        <div className={`${styles.BuLubesHsseContainer}`}>
            <FullScreen handle={handle} onChange={reportChange}>
                <div className={`d-flex align-items-center justify-content-between ${styles.headerContainer}`}>
                    <div className={`${styles.headerLeft}`}>
                        <div className={`${styles.heading}`}><span>HSSE - Daily </span>
                            {(updatedStatus === "Updated") && <div className={`${styles.statusUpdatedImg}`}>
                                <img src={greenTick} className="greenTik" alt='Expand Icon'></img>
                            </div>}
                            </div>
                            <div style={{marginTop:'18px'}}></div>
                    </div>
                </div>
                <div className={`${styles.contentContainer}`}>
                    {
                        isNoDataAvailable ?
                            <NoDataFound />
                            :
                            <>
                                <div className={`d-flex ${styles.contentRow1}`}>
                                    <div className={`d-flex flex-column justify-content-center ${styles.graphHeaderSection1}`}>
                                        <div className={`${styles.graphHeaderSectionTop}`}>
                                            <span className={`${styles.topLeft}`}>{
                                                isFinite(esaData.actual) ?
                                                    numberFormatNoSeparator(esaData.actual) : 'N/A'
                                            }%</span>
                                            {/* <span style={{ color: `${Math.sign(esaData.growth) === 1 ? '#74ce12' : '#e13219'}` }} className={`${styles.topRight}`}>{numberFormatNoSeparator(esaData.growth)}%</span> */}
                                        </div>
                                        <div className={`${styles.graphHeaderSectionBottom}`}><span>ESA Compliance (%)</span></div>
                                    </div>
                                    <div className={`d-flex flex-column justify-content-center ${styles.graphHeaderSection12}`}>
                                        <div className={`${styles.graphHeaderSectionTop}`}>
                                            <span className={`${styles.topLeft}`}>{numberFormatNoSeparator(isaData.actual)}%</span>
                                            {/* <span style={{ color: `${Math.sign(isaData.growth) === 1 ? '#74ce12' : '#e13219'}` }} className={`${styles.topRight}`}>{numberFormatNoSeparator(isaData.growth)}%</span> */}
                                        </div>
                                        <div className={`${styles.graphHeaderSectionBottom}`}><span>ISA Compliance (%)</span></div>
                                    </div>
                                </div>
                                <div className={`d-flex ${styles.contentRow1}`}>
                                    <div className={`flex-column col-6 justify-content-center ${styles.graphHeaderSection2}`}>
                                        <div className={`${styles.graphHeaderSectionTop}`}>
                                            <span className={`${styles.topLeft}`}>{numberFormatNoSeparator(complianceData.actual)}%</span>
                                            {/* <span style={{ color: `${Math.sign(complianceData.growth) === 1 ? '#74ce12' : '#e13219'}` }} className={`${styles.topRight}`}>{numberFormatNoSeparator(complianceData.growth)}%</span> */}
                                        </div>
                                        <div className={`${styles.graphHeaderSectionBottom}`}><span>CAPA Compliance (%)</span></div>
                                    </div>
                                    <div className={`flex-column col-6 justify-content-center ${styles.graphHeaderSection21}`}>
                                        <div className={`${styles.graphHeaderSectionTop}`} >
                                            {/* <span className={`${styles.topLeft}`}>{numberFormatNoSeparator(ltifrData.actual)}</span> */}
                                            <span className={`${styles.topLeft}`}>{incidentData.actual && incidentData.actual}</span>
                                            {/* <span style={{ color: `${Math.sign(ltifrData.growth) === 1 ? '#74ce12' : '#e13219'}` }} className={`${styles.topRight}`}>{numberFormatNoSeparator(ltifrData.growth)}%</span> */}
                                        </div>
                                        <div className={`${styles.graphHeaderSectionBottom}`}><span>Incident Reported (Nos.)</span></div>
                                    </div>
                                </div>


                                <div className={`${styles.barContainer}`}>
                                    {/* <div className={`d-flex justify-content-between align-items-center py-3 ${styles.barHeader}`}>
                                        <span className={`${styles.barHeaderTitle}`}>Nearmiss Reported  (Nos.)</span>
                                        <div>
                                            <span className={`${styles.topLeft}`}>{numberFormatNoSeparator(nearmissData.actual)}</span>
                                        </div>
                                    </div> */}
                                    {/* <div className={'d-flex'} >
                                        <div className={`d-flex flex-column justify-content-center ${styles.progressBar}`} style={{ width: `25%` }}>
                                            <span className={`pl-3 ${styles.percentageBarGreen}`} >{numberFormatNoSeparator(incidentData.minor)}</span>
                                            <span className="pl-3 pt-2">Minor</span>
                                        </div>
                                        <div className={`d-flex flex-column  ${styles.progressBar}`} style={{ width: `25%` }}>
                                            <span className={`pl-3 ${styles.percentageBarOrange}`}>{numberFormatNoSeparator(incidentData.serious)}</span>
                                            <span className="pl-3 pt-2">Serious</span>
                                        </div>
                                        <div className={`d-flex flex-column ${styles.progressBar}`} style={{ width: `25%` }}>
                                            <span className={`pl-3 ${styles.percentageBarRed}`} >{numberFormatNoSeparator(incidentData.major)}</span>
                                            <span className="pl-3 pt-2">Major</span>
                                        </div>
                                        <div className={`d-flex flex-column ${styles.progressBar}`} style={{ width: `25%` }}>
                                            <span className={`pl-3 ${styles.percentageBarBlue}`} >{numberFormatNoSeparator(othervalue)}</span>
                                            <span className="pl-3 pt-2">Others</span>
                                        </div>
                                    </div> */}
                                </div>
                                <img className={`${styles.expandIcon}`} src={expandIcon} alt='Expand Icon' onClick={fullScrn}></img>
                            </>
                    }
                </div>

            </FullScreen>
        </div>
    )
}
export default BuHsse