import React, { useState, useEffect } from 'react'
import mapStyles from './mapStyles'
import styles from './GoogleMapComponent.module.scss'
import { GoogleMap, Polygon, Polyline } from 'react-google-maps'
import CustomInfoBox from './CustomInfoBox'
import BuLpgMapTable from '../BuLpgMapTable/BuLpgMapTable'
import statesJson from './indiaRegions.json'
import StateMap from './StateMap'
import { getColourFromRegion } from '../../../utility/buMapUtility'
import { Spinner } from 'react-bootstrap'
import heatmapLegend from '../../../assets/images/HMap.png'
import { fetchRetailGoogleMap, fetchLPGStateGoogleMap } from '../../../services/bu'
import {isEmpty} from 'lodash'

function GoogleMapComponent(props) {
    const [activeKpi, setactiveKpi] = useState('all') 
    const [responseData, setresponseData] = useState([]) 
    const [responseStateData, setresponseStateData] = useState([]) 
    const [loading, setloading] = useState(true)    
    useEffect(() => {
        // fetchLpgGoogleMap(props.year)
        //     .then((response) => {
        //         if(isEmpty(response.data)){
        //             setresponseData([])
        //             setloading(true)
        //         }else{
        //             setresponseData(response.data)
        //             setloading(false)
        //         }
        //     })
        //     .catch((error) => console.debug(error))
        fetchLPGStateGoogleMap(props.year)
        .then(response => {
            if(isEmpty(response.data)){
                setresponseStateData([])
                setloading(true)
            } else {
                setresponseData(response.data)
                setresponseStateData(response.data1) //for heatmap
                setloading(false)
            }
        }) 
        .catch(error => console.debug(error))
    }, [props.mapOf, props.year])

    const onRegionClick = (e, region) => {
        console.debug(e)
        props.handleRegionDropdown(region.name, e.latLng, 6.2)
    }

    const onKpiTabChange = (kpiToShow) => {
        setactiveKpi(kpiToShow)
    }
        return loading ? (
            <Spinner animation="border" role="status">
                <span className="sr-only">Loading...</span>
            </Spinner>
        ) : (
            <GoogleMap
                className={`${styles.noCloseButton}`}
                center={props.mapCenter}
                zoom={props.mapZoom}
                defaultOptions={{
                    styles: mapStyles,
                    mapTypeControl: false,
                    disableDoubleClickZoom: true,
                    zoomControl: false,
                    scaleControl: false,
                    streetViewControl: false,
                    rotateControl: false,
                    fullscreenControl: false,
                }}
                ref={props.onMapMounted}
                onClick={(e) => {
                    console.debug('FROM MAP', e.latLng)
                }}
            >
                <div className={`${styles.mapKpiSelectorContainer}`}>
                    <div
                        onClick={() => onKpiTabChange('all')}
                        className={`d-flex align-items-center justify-content-center flex-column ${styles.mapKpiSelector
                            } ${activeKpi === 'all' ? styles.active : ''}`}
                    >
                        ALL
                    </div>
                    {/* <div
                        onClick={() => this.onKpiTabChange('sales')}
                        className={`d-flex align-items-center justify-content-center flex-column ${
                            styles.mapKpiSelector
                        } ${activeKpi === 'sales' ? styles.active : ''}`}
                    >
            SALES
                    </div>
                    <div
                        onClick={() => this.onKpiTabChange('revenue')}
                        className={`d-flex align-items-center justify-content-center flex-column ${
                            styles.mapKpiSelector
                        } ${activeKpi === 'revenue' ? styles.active : ''}`}
                    >
            REVENUE
                    </div>
                    <div
                        onClick={() => this.onKpiTabChange('market share')}
                        className={`d-flex align-items-center justify-content-center flex-column ${
                            styles.mapKpiSelector
                        } ${activeKpi === 'market share' ? styles.active : ''}`}
                    >
            MARKET SHARE
                    </div> */}
                    {/* <div onClick={() => this.onKpiTabChange('hsse')} className={`d-flex align-items-center justify-content-center flex-column ${styles.mapKpiSelector} ${activeKpi === 'hsse' ? styles.active : ''}`}>HSSE</div>
                        <div onClick={() => this.onKpiTabChange('plant')} className={`d-flex align-items-center justify-content-center flex-column ${styles.mapKpiSelector} ${activeKpi === 'plant' ? styles.active : ''}`}>PLANT</div>
                        <div onClick={() => this.onKpiTabChange('supply')} className={`d-flex align-items-center justify-content-center flex-column ${styles.mapKpiSelector} ${activeKpi === 'supply' ? styles.active : ''}`}>SUPPLY</div> */}
                </div>
                {/* <div
                    className={`d-flex align-items-center justify-content-around p-2 ${styles.tabLabelsContainer}`}
                >
                    <div className="d-flex align-items-center">
                        <span className={`mr-2 ${styles.poorDot}`}></span>North
                    </div>
                    <div className="d-flex align-items-center">
                        <span className={`mr-2 ${styles.fairDot}`}></span>East
                    </div>
                    <div className="d-flex align-items-center">
                        <span className={`mr-2 ${styles.goodDot}`}></span>West
                    </div>
                    <div className="d-flex align-items-center">
                        <span className={`mr-2 ${styles.excellentDot}`}></span>South
                    </div>
                </div> */}
                    {
                        props.mapOf === 'all regions' &&
                        <div className={styles.heatmapLegend}><img className={styles.heatmapImg} src={heatmapLegend} />
                            <div className={styles.heatmapMax}>{(responseData[0].sales_actual).toFixed()} TMT</div>
                            {/* <div className={styles.heatmapMiddle}>{(responseData[0].sales_actual/2).toFixed()}</div> */}
                            <div className={styles.heatmapMin}>{(responseData[responseData.length - 1].sales_actual).toFixed()} TMT</div>
                        </div>
                    }
                <BuLpgMapTable
                    activeKpi={activeKpi}
                    activeTab={props.activeTab}
                    mapOf={props.mapOf}
                    year={props.year}
                ></BuLpgMapTable>
                {props.mapOf === 'all regions' &&
                    props.activeTab === 'lpg_tab'
                    ? responseStateData.regions.map((regionItem, index) => {
                        let responseRegion = responseData.find(
                            (responseDataItem) => {
                                return responseDataItem.region === regionItem.name
                            }
                        )
                        return (
                            responseRegion && (
                                <React.Fragment key={index}>
                                    {/* {responseRegion && (
                                        <CustomInfoBox
                                            kpiSelection={activeKpi}
                                            locationData={responseRegion}
                                            anchorPosition={
                                                regionItem.data.markers[0].coordinates.slice(-1)[0]
                                            }
                                            markerPixelOffset={{ x: 135, y: 120 }}
                                        ></CustomInfoBox>
                                    )} */}
                                    {regionItem.data.polygons.map((polygon, index) => {
                                        return (
                                            <Polygon
                                                key={index}
                                                path={polygon.coordinates}
                                                options={{
                                                    fillColor: `${getColourFromRegion(polygon.name, polygon.color)}`,
                                                    fillOpacity: 1,
                                                    strokeColor: '#ffffff',
                                                    strokeOpacity: 0.2,
                                                    strokeWeight: 1,
                                                }}
                                            //onClick={(e) => this.onRegionClick(e, regionItem)}
                                            ></Polygon>
                                        )
                                    })}
                                    {/* <Polyline
                                        path={regionItem.data.markers[0].coordinates}
                                        options={{
                                            strokeColor: '#ffffff',
                                            strokeOpacity: 1,
                                            strokeWeight: 1,
                                        }}
                                    ></Polyline> */}
                                </React.Fragment>
                            )
                        )
                    })
                    : props.activeTab === 'lpg_tab' && (
                        <StateMap
                            activeTab={props.activeTab}
                            kpiSelection={activeKpi}
                            regionName={props.mapOf}
                            year={props.year}
                            statesJsonDb={responseStateData}
                        ></StateMap>
                    )}
            </GoogleMap>
        )
}

export default GoogleMapComponent
