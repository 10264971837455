import { PLANNING_KPI_URL, ORG_KPI_URL } from '../environment'
import { withAuthorizationHeaders } from '../components/Authentication/services/authHeaders'
import axios from 'axios'

export const fetchMouGetOverall = async () => {
    let url = `${PLANNING_KPI_URL}/getOverall`
    const res = await axios.get(url, { withCredentials: true })
    return res.data
}
export const fetchMouMandatory = async (kpiName, year) => {
    let url = `${PLANNING_KPI_URL}/mandatory/data/${encodeURIComponent(kpiName)}?year=${year}`
    const res = await axios.get(url, { withCredentials: true })
    return res.data
}

export const fetchMouMandatoryKpi = async (year) => {
    let url = `${PLANNING_KPI_URL}/mandatory?year=${year}`
    const res = await axios.get(url, { withCredentials: true })
    return res.data
}

export const fetchMouOtherKpi = async () => {
    let url = `${PLANNING_KPI_URL}/other`
    const res = await axios.get(url, { withCredentials: true })
    return res.data
}

export const fetchMouLanding = async () => {
    const res = await axios.get(
        `${ORG_KPI_URL}/orgyr`,
        { withCredentials: true }
    )
    return res.data
}
