export const BASE_URL =(process.env.REACT_APP_API_BASE_URL || 'https://qa.cdc.bpcl.in/cdc').replace(/\/*$/, '')
export const PLANNING_KPI_URL= `${BASE_URL}/api/bpcl/planning`
export const REFINERY_KPI_URL= `${BASE_URL}/api/bpcl/refinery`
export const ITRM_KPI_URL= `${BASE_URL}/api/bpcl/itrm`
export const BU_KPI_URL= `${BASE_URL}/api/bpcl/business_unit`
export const ORG_KPI_URL= `${BASE_URL}/api/bpcl/orgkpis`

export default {
  PLANNING_KPI_URL,
  REFINERY_KPI_URL,
  ITRM_KPI_URL,
  BU_KPI_URL,
  ORG_KPI_URL,
  BASE_URL
};
